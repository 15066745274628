import { TDispatch } from '..';
import { createAction } from '@reduxjs/toolkit';

import { getAvxSessionData } from './fetchers';

const LOAD_DATA = 'neuro-ui/readonly/LOAD_DATA';
const CLEAR_DATA = 'neuro-ui/readonly/CLEAR_DATA';

const loadDataAction = createAction(LOAD_DATA, (readonlyData: IReadonlyStore) => {
  return {
    payload: readonlyData,
  };
});

const clearDataAction = createAction(CLEAR_DATA, () => {
  return { payload: null };
});

const loadReadonlyData =
  (resMed?: boolean) =>
  async (dispatch: TDispatch): Promise<void> => {
    const [documents] = resMed ? await Promise.all([getAvxSessionData()]) : [];
    dispatch(loadDataAction({ documents: documents || [] }));
  };

export const reduxActions = {
  loadDataAction,
  clearDataAction,
};

export const actions = {
  loadReadonlyData,
  clearData: clearDataAction,
};
