/**
 * Inject one, or array of children with documents loaded from redux state
 */
import { concat, filter, find } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';

import { getEnabledDocuments } from '../../utility/randomUtil';

type Props = IStateFromProps & IOwnProps;

class DocumentLoader extends React.Component<Props> {
  public render(): JSX.Element | JSX.Element[] {
    if (Array.isArray(this.props.children)) {
      return this.props.children.map((child) =>
        React.createElement(child, { documents: this.props.documents, name: this.props.name }, null),
      );
    } else {
      return React.createElement(this.props.children, { documents: this.props.documents, name: this.props.name });
    }
  }
}

interface IStateFromProps {
  documents: TAnyObject[];
}

const mapStateToProps = (state: IState, props: IOwnProps): IStateFromProps => {
  const enabledName = find(
    (d: { name: string; subTypes?: string[]; secondaryDocs?: string[] }) => d.name === props.name,
    getEnabledDocuments(state.session),
  );
  let docArray: any[] = [];
  if (enabledName && enabledName.subTypes) {
    // Filter specific documents based on filtering function
    const subTypeFilter = (singleDoc: IControlProps) =>
      enabledName.subTypesFiltering && enabledName.subTypesFiltering[singleDoc._type]
        ? enabledName.subTypesFiltering[singleDoc._type]?.(singleDoc)
        : true;

    enabledName.subTypes.forEach((t: string) => {
      const arr = filter((d) => d._type === t && subTypeFilter(d), state.documents.sortedAndMergedDocuments ?? []);
      docArray = arr.length > 0 ? concat(docArray, arr) : docArray;
    });
    // Get secondary documents
    if (enabledName.secondaryDocs) {
      enabledName.secondaryDocs.forEach((t: string) => {
        const arr = filter((d) => d._type === t, state.documents.sortedAndMergedDocuments ?? []);
        docArray = arr.length > 0 ? concat(docArray, arr) : docArray;
      });
    }
    const docsWithCommits = docArray.filter((d) => d._cid);
    return {
      documents: docsWithCommits.length > 0 ? docsWithCommits : [],
    };
  } else {
    docArray = filter((d) => d._type === props.name, state.documents.sortedAndMergedDocuments ?? []);
    const docsWithCommits = docArray.filter((d) => d._cid);
    return {
      documents: docsWithCommits.length > 0 ? docsWithCommits : [],
    };
  }
};

interface IOwnProps {
  name: string;
  children:
    | Array<({ documents }: { documents: any; name: string }) => JSX.Element>
    | (({ documents }: { documents: any; name: string }) => JSX.Element);
}

export default connect(mapStateToProps)(DocumentLoader);
