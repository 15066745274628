import * as React from 'react';
import { styled as muiStyled } from '@mui/material';
import { IGraphDimensions, ILegend } from 'Components/sq-graphics/interfaces';
import { GraphTooltipDescription } from '../GraphTooltip/components';
import { GraphTooltip } from '../GraphTooltip';
import colors from '../../../../config/theme/colors';

const StyledLegendsContainer = muiStyled('div')(({ dimensions }: { dimensions: IGraphDimensions }) => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexWrap: 'wrap' as const,
  marginTop: `${dimensions.legends.marginTop}rem`,
  marginBottom: `${dimensions.legends.marginBottom}rem`,
  paddingTop: `${dimensions.legends.paddingTop}rem`,
  paddingBottom: `${dimensions.legends.paddingBottom}rem`,
  paddingLeft: '1rem',
  paddingRight: '1rem',
  boxSizing: 'border-box' as const,
  border: '1px solid #E8E8E8',
  borderRadius: '0.2rem',
  justifyContent: 'center',
  backgroundColor: 'white',
  position: 'relative' as const,
  zIndex: 2,
}));

const AddonLegendWrapper = muiStyled('div')({
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'row',
  marginTop: '0.5rem',
});

const StyledColumn = muiStyled('div')((props: { width: number; padding?: string }) => ({
  width: `${props.width}rem`,
  height: '100%',
  padding: props.padding ?? 0,
  boxSizing: 'border-box' as const,
}));

const StyledLegend = muiStyled('div', { shouldForwardProp: (prop) => prop !== 'isExtra' })(
  ({ isExtra }: { isExtra?: boolean }) => ({
    height: '20px',
    width: 'auto',
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
    marginRight: '2rem',
    marginLeft: isExtra ? '-2rem' : undefined,
  }),
);

const StyledTitle = muiStyled('div', { shouldForwardProp: (prop) => prop !== 'isExtra' })(
  (props: { isExtra?: boolean }) => ({
    fontSize: '1.4rem',
    color: props.isExtra ? colors.tertiaryText : '#000000',
    padding: '0 0.5rem',
    userSelect: 'none' as const,
  }),
);

interface ILegendProps {
  legend: ILegend;
}

interface IAddonLegendsProps {
  legends: ILegend[];
  timeframeWidth: number;
  dimensions: IGraphDimensions;
}

export const AddonLegends = React.memo(
  ({ legends, timeframeWidth, dimensions }: IAddonLegendsProps): JSX.Element => (
    <AddonLegendWrapper>
      <StyledColumn width={dimensions.leftColumn.width}></StyledColumn>
      <StyledColumn width={timeframeWidth}>
        <Legends legends={legends} dimensions={dimensions} />
      </StyledColumn>
      <StyledColumn width={dimensions.rightColumn.width}></StyledColumn>
    </AddonLegendWrapper>
  ),
);

const Legend = ({ legend }: ILegendProps): JSX.Element =>
  legend.legend ? (
    legend.legendDescription ? (
      <GraphTooltip
        title={legend.legendDescription.title ?? ''}
        description={
          legend.legendDescription.description ? (
            <GraphTooltipDescription convertedData={legend.legendDescription.description} />
          ) : (
            ''
          )
        }
        content={
          <StyledLegend>
            {legend.icon ? (
              legend.icon
            ) : (
              <div style={{ height: '14px', width: '14px', marginLeft: '3px', backgroundColor: legend.color }} />
            )}
            <StyledTitle>{legend.legend}</StyledTitle>
          </StyledLegend>
        }
      />
    ) : (
      <StyledLegend isExtra={legend.isExtra}>
        {legend.icon ? (
          legend.icon
        ) : legend.color ? (
          <div style={{ height: '14px', width: '14px', marginLeft: '3px', backgroundColor: legend.color }} />
        ) : undefined}
        <StyledTitle isExtra={legend.isExtra}>{legend.legend}</StyledTitle>
      </StyledLegend>
    )
  ) : (
    <></>
  );

export const Legends = React.memo(({ legends, dimensions, alwaysShow }: IOwnProps): JSX.Element => {
  if (!legends || (legends.length <= 1 && !alwaysShow)) return <></>;
  // Hide "secret" datapoint set (used in seizuresPerMontByType)
  const legs = legends.filter((l) => l.legend !== '');
  return (
    <StyledLegendsContainer dimensions={dimensions}>
      {legs.map((l) => (
        <Legend legend={l} key={l.id} />
      ))}
    </StyledLegendsContainer>
  );
});

interface IOwnProps {
  legends: ILegend[];
  dimensions: IGraphDimensions;
  alwaysShow?: boolean;
}
