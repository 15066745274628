import * as React from 'react';

import { field, historyValue } from '../../../config/theme/componentTheme';
import ToolTip from 'Components/ToolTip';
import { styled } from '@mui/system';
import { omit } from 'ramda';

const StyledDiv = styled('div')({
  width: '100%',
});

const StyledInput = styled('input', {
  shouldForwardProp: (prop) =>
    !['fullWidth', 'fontSize', 'fontColor', 'backgroundColor', 'centered'].includes(
      typeof prop === 'string' ? prop : '',
    ),
})<IInputProps>(({ width, fullWidth, height, fontSize, fontColor, backgroundColor, centered }: IInputProps) => ({
  boxSizing: 'border-box',
  width: fullWidth ? '100%' : width ? width + 'rem' : field.width,
  height: height ? height + 'rem' : field.height,
  borderRadius: field.borderRadius,
  fontSize: fontSize ? fontSize.toString() + 'rem' : field.fontSize,
  color: fontColor ? fontColor : undefined,
  fontWeight: field.fontWeight,
  textAlign: centered ? 'center' : 'left',
  fontFamily: 'Titillium Web',
  letterSpacing: field.letterSpacing,
  boxShadow: field.boxShadow,
  border: field.border,
  padding: field.padding,
  backgroundColor: backgroundColor ? backgroundColor : undefined,
}));

interface IInputProps {
  width?: string | number;
  fullWidth?: boolean;
  height?: string | number;
  fontSize?: string | number;
  fontColor?: string;
  backgroundColor?: string;
  centered?: boolean;
}

const isSameValue = (value1: string, value2: string): boolean => {
  return value1 === value2;
};

/**
 * Regular Expressions that will determine how the string values
 * will be parsed into float values.
 */
const matchNumerical = /^([\d\\,\\.\\-\\+]+)$/;
const matchFloat = (precision: number): RegExp => {
  return new RegExp(
    '^[+-]?[1-9]{1}[0-9]*([.]{1}[0-9]' +
      (precision > 1 ? '{0,' + (precision - 1).toString() + '}' : '{0}') +
      '[1-9]{1})' +
      (precision > 0 ? '{0,' + precision.toString() + '}' : '{0}') +
      '$' +
      '|' +
      '^[+-]?[0]{1}([.]{1}[0-9]' +
      (precision > 1 ? '{0,' + (precision - 1).toString() + '}' : '{0}') +
      '[1-9]{1})' +
      (precision > 0 ? '{0,' + precision.toString() + '}' : '{0}') +
      '$',
    'i',
  );
};
const matchString = (precision: number): RegExp => {
  return new RegExp(
    '^[+-]?[0]{1}([.]{1}[0-9]' +
      (precision > 1 ? '{0,' + (precision - 1).toString() + '}' : '{0}') +
      '[0]{1}){' +
      (precision > 0 ? 1 : 0).toString() +
      '}$' +
      '|' +
      '^[+-]?[1-9]{1}[0-9]*([.]{1}[0-9]' +
      (precision > 1 ? '{0,' + (precision - 1).toString() + '}' : '{0}') +
      '[0]{1}){1}$',
    'i',
  );
};

const decimalPointIsLastChar = (value: string | null) => {
  if (!value) return false;
  return value.length > 1 && value.indexOf('.') === value.length - 1;
};

/**
 * Function for changin the data in NumberField and storage
 * @param onChange - Funtion for changing the state of storage
 * @param maxLength - The number of digits the NumberField is allowed to contain
 * @param oldValue - Previous value of NumberField before current change event
 * @param min - The maximum value the NumberField is allowed to contain
 * @param max - The minimum value the NumberField is allowed to contain
 * @param precision - The number of decimals the value in NumberField is allowed to have
 * @param saveAsString - Boolean to save value as string
 * @param allowZeroStart - Boolean to allow values to be saved as '040...' for example. Use with saveAsString
 * @param allowPlusSign - Boolean to allow saving as '+358...' for example. Use with saveAsString
 */
const onChangeNumerical =
  (
    onChange?: IInputBasics['onChange'],
    maxLength?: number,
    oldValue?: string | undefined,
    min?: number,
    max?: number,
    precision?: number,
    saveAsString?: boolean,
    rangeTooltipOpen?: (open: boolean) => void,
    allowZeroStart?: boolean,
    allowPlusSign?: boolean,
  ) =>
  (e: React.ChangeEvent<HTMLInputElement>): string => {
    let value: string | number | undefined | null = oldValue;
    const newValue = e.currentTarget.value.replace(/,/g, '.');

    if ((min || max) && newValue) {
      if ((min || min === 0) && min > parseFloat(newValue)) {
        // Open tooltip if value is invalid
        rangeTooltipOpen?.(true);
        return value || '';
      }
      if ((max || max === 0) && max < parseFloat(newValue)) {
        rangeTooltipOpen?.(true);
        return value || '';
      }
    }

    // Close tooltip when value is valid
    rangeTooltipOpen?.(false);

    if (
      onChange &&
      (maxLength
        ? newValue
            .toString()
            .split(',')
            .filter((e) => e !== '.').length <= maxLength
        : true) &&
      (matchNumerical.test(e.currentTarget.value) || e.currentTarget.value === '')
    ) {
      /**
       * Check if NumberField input value is ready to be parsed into a float value.
       * If not either evaluate 'value' variable with string or null value.
       * String values are for handling situations where symbols or digits could be
       * lost in parseFloat function.
       */
      const notSame =
        (oldValue || oldValue === '' || oldValue === '0') && !isSameValue(oldValue, newValue)
          ? !isSameValue(oldValue, newValue)
          : false;

      if (notSame) {
        // When NumberField value should be empty string and storage value null
        if (newValue.indexOf('.') === 0 || newValue === '') {
          value = null;

          // If newValue is '0'
        } else if (newValue === '0') {
          value = 0;
          // If newValue is (0... something) like when changing the first number
        } else if (newValue.charAt(0) === '0') {
          value = newValue;

          // When NumberField value should be seen but storage value will not be updated
        } else if (
          (newValue.length === 1 && newValue.indexOf('+') === 0) ||
          (newValue.length === 1 && newValue.indexOf('-') === 0) ||
          decimalPointIsLastChar(newValue) ||
          matchString(precision || precision === 0 ? precision : 0).test(newValue)
          // deepcode ignore DuplicateIfBody: Separating these cases for clarity
        ) {
          value = newValue;

          // When both NumberField and storage values will be updated
        } else if (matchFloat(precision || precision === 0 ? precision : 0).test(newValue)) {
          value = parseFloat(newValue);
          if (allowPlusSign && saveAsString && newValue.indexOf('+') === 0) value = `+${parseFloat(newValue)}`;

          // If any other values pass the tests
        } else {
          value = parseFloat(oldValue ?? '0');
        }

        // If any other values pass the tests
      } else {
        value = null;
      }

      if (typeof value === 'number' || value === null) {
        onChange({
          [e.currentTarget.name]: saveAsString && value !== null ? `${value}` : value,
        });
      } else if (typeof value === 'string') {
        if (!isNaN(parseFloat(value))) {
          // FYI: parseFloat deletes decimal points if there is no number after it
          const zeroStart = allowZeroStart && value.charAt(0) === '0';
          const plusSignStart = allowPlusSign && value.charAt(0) === '+';
          onChange({
            [e.currentTarget.name]:
              saveAsString && value !== null
                ? `${zeroStart ? '0' : plusSignStart ? '+' : ''}${parseFloat(value)}`
                : parseFloat(value),
          });
        }
      }
    }
    /**
     * Return the value that will be shown in NumberField
     */
    return value || (typeof value === 'number' && value === 0)
      ? typeof value === 'string'
        ? value
        : value?.toString()
      : '';
  };

/**
 * Sub-component for NumberField where the data handling for NumberField and storage
 * is done seperatedly
 */
const SubField = ({
  value,
  onChange,
  maxLength,
  autoComplete,
  min,
  max,
  precision,
  centered,
  saveAsString = false,
  allowZeroStart = false,
  allowPlusSignStart = false,
  ...other
}: Partial<
  IInputBasics &
    INumberField & { fontColor?: StyledProps['fontColor']; backgroundColor?: StyledProps['backgroundColor'] }
>): JSX.Element => {
  const [inputValue, onInputChange] = React.useState<string | null>(null);
  const [rangeTooltipOpen, setRangeTooltipOpen] = React.useState<boolean>(false);

  const change = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const s = onChangeNumerical(
      onChange,
      maxLength,
      inputValue || '',
      min,
      max,
      precision,
      saveAsString,
      setRangeTooltipOpen,
      allowZeroStart,
      allowPlusSignStart,
    )(e);
    onInputChange(s);
  };

  React.useEffect(() => {
    // If value does not match inputvalue
    // Do not change inputvalue if the inputvalue has a decimal point but no number after it
    if (value !== inputValue && !decimalPointIsLastChar(inputValue)) {
      if (value || value === 0) {
        onInputChange(value.toString());
      } else {
        onInputChange('');
      }
    }
    // This breaks decimals
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <ToolTip
      open={rangeTooltipOpen}
      title={
        <div>
          {(max || max === 0) && <div>{`Arvon yläraja: ${max}`}</div>}
          {(min || min === 0) && <div>{`Arvon alaraja: ${min}`}</div>}
        </div>
      }
      content={
        <StyledInput
          value={inputValue || ''}
          onChange={change}
          maxLength={maxLength}
          autoComplete={autoComplete ? 'on' : 'off'}
          onBlur={() => setRangeTooltipOpen(false)}
          centered={centered}
          {...other}
        />
      }
    ></ToolTip>
  );
};

/**
 * The main component
 */
const NumberField = ({ editing = false, value, style, ...other }: IInputBasics & INumberField): JSX.Element => {
  return !editing ? (
    <StyledDiv
      style={{
        ...historyValue,
        ...{
          color: style && style.color ? `${style.color}` : undefined,
          backgroundColor: style && style.backgroundColor ? `${style.backgroundColor}` : undefined,
          padding: style && style.backgroundColor ? '0 2px 0 2px' : 0,
          borderRadius: style && style.backgroundColor ? '0.5rem' : 0,
        },
      }}
    >
      {value || '-'}
    </StyledDiv>
  ) : (
    <SubField
      value={value}
      fontColor={style && style.color ? `${style.color}` : undefined}
      backgroundColor={style && style.backgroundColor ? `${style.backgroundColor}` : undefined}
      {...omit(['optionFormatter', 'options'], other)}
    />
  );
};

interface StyledProps {
  width?: number;
  height?: number;
  fontSize?: number;
  fontColor?: string;
  backgroundColor?: string;
}

export default NumberField;
