import {
  Add,
  AddBox,
  ArrowDropDown,
  ArrowRight,
  ArrowDropUp,
  Description,
  Lock,
  MergeType,
  Notifications,
  Pause,
  PinDrop,
  PlayArrow,
  Repeat,
  SkipNext,
  Stop,
  Menu,
  Close,
  WarningAmber,
  Sync,
  Cancel,
  CheckCircle,
  HelpCenter,
  KeyboardDoubleArrowRight,
  Visibility,
} from '@mui/icons-material';
import { SvgIcon, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/types';
import * as React from 'react';

const SQIcon = ({ ...props }) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M15 4C21.0752 4 26 8.92493 26 14.9999C26 21.075 21.0752 25.9999 15 25.9999C8.92482 25.9999 4 21.075 4 14.9999C4 8.92493 8.92482 4 15 4Z"
        fill="white"
      />
      <mask
        id="mask0_265_7282"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="22"
        height="22"
      >
        <path
          d="M15 4C21.0752 4 26 8.92493 26 14.9999C26 21.075 21.0752 25.9999 15 25.9999C8.92482 25.9999 4 21.075 4 14.9999C4 8.92493 8.92482 4 15 4Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_265_7282)">
        <path d="M27.0212 2.97827H2.97754V27.0219H27.0212V2.97827Z" fill="url(#paint0_linear_265_7282)" />
        <path
          d="M17.9988 13.1379L17.995 13.1313C17.9342 13.9127 17.0834 14.6115 14.7496 15.7856C14.1676 16.0785 13.7104 16.3315 13.3482 16.5537H17.4238C17.5245 16.5537 17.6066 16.6358 17.6066 16.7365C17.6066 16.8371 17.5245 16.9193 17.4238 16.9193H12.7955C12.5022 17.131 12.3061 17.3095 12.1637 17.4671H18.0628C18.164 17.4671 18.2456 17.5488 18.2456 17.6499C18.2456 17.7505 18.164 17.8327 18.0628 17.8327H12.4039C12.6061 18.0131 12.8525 18.1949 13.1393 18.3805H16.6932C16.7938 18.3805 16.8755 18.4622 16.8755 18.5633C16.8755 18.6639 16.7938 18.7461 16.6932 18.7461H13.7427C14.1182 18.9602 14.5407 19.1823 15.0041 19.4154C17.421 20.6308 17.9427 21.3025 17.9988 21.9373V23.1336C17.9988 23.1654 17.9546 23.1726 17.9442 23.1426C17.6835 22.3755 16.5346 21.6624 14.8051 20.7931C12.3735 19.5712 11.8266 18.9027 11.7559 18.2727L11.7582 18.2761V16.7934C11.7587 16.7982 11.7592 16.8025 11.7597 16.8067C11.8223 16.1758 12.354 15.505 14.7529 14.2987C15.344 14.0015 15.9498 13.6853 16.4758 13.3573H12.2178C12.1167 13.3573 12.035 13.2751 12.035 13.1745C12.035 13.0734 12.1167 12.9917 12.2178 12.9917H17.0165C17.2591 12.8118 17.4694 12.629 17.6327 12.4439H12.2178C12.1167 12.4439 12.035 12.3622 12.035 12.2611C12.035 12.1604 12.1167 12.0783 12.2178 12.0783H17.3792C17.1803 11.8979 16.9367 11.7161 16.6524 11.5305H13.0396C12.9389 11.5305 12.8568 11.4488 12.8568 11.3477C12.8568 11.247 12.9389 11.1649 13.0396 11.1649H16.0513C15.6815 10.9532 15.2652 10.7348 14.808 10.505C12.3768 9.28255 11.8328 8.61459 11.762 7.98461L11.7582 7.98793V6.75978C11.7582 6.72797 11.8024 6.72037 11.8128 6.75028C12.0815 7.52126 13.247 8.24334 15.0041 9.12684C17.421 10.3427 17.9427 11.0144 17.9988 11.6491V13.1379Z"
          fill="#96C8D5"
          fillOpacity="0.46"
        />
        <path
          d="M18.2078 16.9131C18.2083 16.9089 18.2088 16.9046 18.2093 16.9003V18.3829L18.2116 18.3791C18.1409 19.0096 17.5935 19.6776 15.1624 20.8996C13.4329 21.7693 12.284 22.4819 12.0233 23.2495C12.0129 23.2794 11.9688 23.2718 11.9688 23.24V22.0442C12.0248 21.4094 12.5465 20.7377 14.9634 19.5219C16.3426 18.8283 17.3576 18.2339 17.8599 17.6381C17.5313 17.2497 16.8933 16.7351 15.2179 15.892C12.8841 14.7184 12.0333 14.0196 11.9721 13.2382L11.9688 13.2448V11.7555L11.9702 11.7408C12.0333 11.1104 12.565 10.4396 14.9634 9.23372C16.7205 8.34975 17.886 7.62767 18.1547 6.85716C18.1651 6.82725 18.2093 6.83437 18.2093 6.86618V8.09434L18.2055 8.09101C18.1347 8.721 17.5907 9.38943 15.1595 10.6114C13.7818 11.3041 12.7744 11.897 12.2897 12.4976C12.8513 13.1741 14.0614 13.8249 15.2146 14.4051C17.6135 15.6119 18.1452 16.2827 18.2078 16.9131Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_265_7282"
          x1="14.9994"
          y1="2.97827"
          x2="14.9994"
          y2="27.0219"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#045A8B" />
          <stop offset="1" stopColor="#2B3870" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);

export type TAvailableIcons =
  | 'play'
  | 'next'
  | 'pause'
  | 'stop'
  | 'repeat'
  | 'pinDrop'
  | 'noteAdd'
  | 'description'
  | 'mergeType'
  | 'sqicon'
  | 'lock'
  | 'rightArrow'
  | 'downArrow'
  | 'upArrow'
  | 'bell'
  | 'plus'
  | 'menu'
  | 'close' // X
  | 'alert'
  | 'sync'
  | 'cancel' // X inverse
  | 'success' // X inverse
  | 'question'
  | 'doubleArrowRight'
  | 'visibility';

// Adjusted viewboxes for some icons for them to scale/position more nicely
export const customViewBox = {
  cancel: '0 0 24 24',
  success: '0 0 24 24',
};

export type TCustomViewBoxKey = keyof typeof customViewBox;

export const Icons: { [icon in TAvailableIcons]: OverridableComponent<SvgIconTypeMap<any, 'svg'>> } = {
  play: PlayArrow,
  next: SkipNext,
  pause: Pause,
  stop: Stop,
  repeat: Repeat,
  pinDrop: PinDrop,
  noteAdd: AddBox,
  description: Description,
  mergeType: MergeType,
  sqicon: SQIcon,
  lock: Lock,
  rightArrow: ArrowRight,
  downArrow: ArrowDropDown,
  upArrow: ArrowDropUp,
  bell: Notifications,
  plus: Add,
  menu: Menu,
  close: Close,
  alert: WarningAmber,
  sync: Sync,
  cancel: Cancel,
  success: CheckCircle,
  question: HelpCenter,
  doubleArrowRight: KeyboardDoubleArrowRight,
  visibility: Visibility,
};
