import { Theme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { take } from 'ramda';
import * as React from 'react';

import { RenderValue } from './common/commonComponents';
import { dateCell, ellipsis, height, table, titleCell, valueCell } from './common/commonStyles';
import { IData } from './definitions';

const DataTable = styled('table')(table);

const DataRow = styled('tr')(({ theme }) => ({
  height: height,
  maxHeight: height,
  ':nth-of-type(odd)': {
    backgroundColor: theme.palette.grey[200],
  },
}));

const DataCellDate = styled('td')(({ theme }: { theme?: Theme }) => ({
  ...dateCell(theme),
}));

const DataCellTitle = styled('td', {
  shouldForwardProp: (prop) => prop !== 'hasValue',
})(({ theme, hasValue }: { theme?: Theme; hasValue: boolean }) => ({
  ...titleCell(theme, true, hasValue),
  ...ellipsis(18),
}));

const DataCellValue = styled('td')(({ theme }: { theme?: Theme }) => ({
  ...valueCell(theme),
}));

const RecentList = ({ data }: IRecentList) => {
  const itemsCanFitAmount = 6;
  const shownItems = take(itemsCanFitAmount, data);
  const renderValueCol = data.some((d) => d.endDate || d.value);
  return (
    <DataTable>
      <tbody>
        {shownItems.map((d) => (
          <DataRow key={d.key}>
            <DataCellDate>{d.date}</DataCellDate>
            <DataCellTitle hasValue={!!d.value}>{d.title}</DataCellTitle>
            {renderValueCol && (
              <DataCellValue>
                <RenderValue value={d.value} endDate={d.endDate} />
              </DataCellValue>
            )}
          </DataRow>
        ))}
      </tbody>
    </DataTable>
  );
};

export interface IRecentList {
  type: 'recentList';
  data: Array<IData>;
}

export default RecentList;
