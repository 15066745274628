import { mgravisDiagnoses } from 'Routes/Diagnosis/utils/definitions';
import { LoadableDocConfig } from '../generalSettings';
import { sortPartialDate } from 'neuro-utils';

export const mgravisDocumentTypes: LoadableDocConfig[] = [
  {
    name: 'diagnosis',
    subTypes: ['diagnosis', 'mgravisSymptoms'],
  },
  { name: 'medication', subTypes: ['medication', 'adverseEffect'] },
  { name: 'functionalPerformanceMgravis', subTypes: ['mgc', 'mgadl', 'passTest'] },
  { name: 'comorbidity', subTypes: ['hospitalization'] },
  {
    name: 'procedure',
    subTypes: ['procedure'],
    subTypesFiltering: { procedure: (d) => d?.code?.code !== '20720000' && d?.code?.code !== '1269349006' },
  },
  { name: 'imaging', subTypes: ['thymusCt', 'thymusMri'] },
  { name: 'clpAndCnpTests', subTypes: ['diagnosticExamination'] },
  { name: 'examination' },
  { name: 'diagnosticTests', subTypes: ['diagnosticExamination'] },
  { name: 'background', subTypes: ['background', 'measurement'] },
];

export const mgravisGraphDocTypes = [
  'diagnosis',
  'mgravisSymptoms',
  'medication',
  'adverseEffect',
  'mgc',
  'mgadl',
  'passTest',
  'background',
  'diagnosticExamination',
  'procedure',
  'hospitalization',
  'thymusCt',
  'thymusMri',
];

export const mgravisAlerts: IAlert[] = [
  {
    // Notification to the user if a diagnosis document is recorded before a mgravisSymptoms document
    content: 'alerts.mgravis.symptomOnsetDateAfterDiagnosisDate',
    id: 'mgravisSymptomOnsetDateAfterDiagnosisDate',
    severity: 'warning',
    popup: true,
    rule: (docs?: Array<IControlProps & Record<string, unknown>>): boolean => {
      // Find first Mgravis diagnosis document (docs should be sorted descending by commitDate)
      const mgravisDiagnosisDoc: IDiagnosis | Record<string, never> =
        (docs || []).find(
          (d) =>
            d._type === 'diagnosis' && 'diagnosis' in d && mgravisDiagnoses.includes((d as IDiagnosis).diagnosis ?? ''),
        ) || {};
      const mgravisDiagnosisDate = mgravisDiagnosisDoc.date;

      // Find first Mgravis diagnosis document (docs should be sorted descending by commitDate)
      const mgravisSymptomsDoc: IMGravisSymptoms | Record<string, never> =
        (docs || []).find((d) => d._type === 'mgravisSymptoms') || {};
      const mgravisSymptomOnsetDate = mgravisSymptomsDoc.date;

      if (!mgravisDiagnosisDate || !mgravisSymptomOnsetDate) return false;
      return sortPartialDate(mgravisSymptomOnsetDate, mgravisDiagnosisDate) > 0;
    },
  },
];
