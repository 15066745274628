import * as React from 'react';
import * as d3Path from 'd3-path';
import { GraphColors, IData, IDataPoint, TGraphScale, TGraphSettings } from 'Components/sq-graphics/interfaces';
import { GraphTooltip, MultipleGraphTooltip } from '../../GraphTooltip';
import { invertColor } from '../../../util/calculators';
import { GraphTooltipDescription } from '../../GraphTooltip/components';
import { sortDate } from 'neuro-utils';

const createPath = (
  points: IPoint[],
  linegraphProps: IData['linegraphProps'],
  graphHeight: number,
  graphWidth: number,
  xPoint: (date: Date) => number | undefined,
): string => {
  if (!points || points.length < 1) return '';
  const gPath = d3Path.path();
  const xAxisLineBreaks = linegraphProps?.xAxisLineBreaks
    ? linegraphProps.xAxisLineBreaks.map((lb) => xPoint(lb))
    : undefined;
  gPath.moveTo(points[0].x, points[0].y);
  points.slice(1).forEach((p, i) => {
    if (xAxisLineBreaks?.some((lb) => lb && lb < p.x && lb > points?.[i].x)) {
      gPath.moveTo(p.x, p.y);
    } else if (linegraphProps?.stepLine) {
      gPath.lineTo(p.x, points[i].y);
      gPath.lineTo(p.x, p.y);
    } else if (linegraphProps?.defaultToZero) {
      gPath.lineTo(points[i].x, graphHeight);
      gPath.lineTo(p.x, graphHeight);
      gPath.lineTo(p.x, p.y);
    } else {
      gPath.lineTo(p.x, p.y);
    }
  });
  if (linegraphProps?.stepLine) gPath.lineTo(graphWidth, points[points.length - 1].y);
  return gPath.toString();
};

const getGraphIds = (data: Array<IData>): string[] => {
  const ids: string[] = [];
  data.forEach((d) => {
    if (ids.includes(d.id)) return;
    ids.push(d.id);
  });
  return ids;
};

const LineGraph = ({
  data,
  xPoint,
  yPoint,
  height,
  width,
  scale,
  additionalScale,
  graphSettings,
  reference,
  numberOfOtherGraphs,
  graphTypeIndex,
  scaleId,
}: IOwnProps): JSX.Element => {
  // All the IData-objects that are of linegraph type are passed to this component
  const ids: string[] = getGraphIds(data);
  // Ledd graph needs to be reversed as leddMax needs to be rendered under ledd
  if (ids.includes('ledd')) {
    ids.reverse();
  }
  const dataPoints: { [key: string]: IPoint[] } = {};
  ids.forEach((id) => {
    const dps: IPoint[] = [];
    data
      .filter((d) => d.id === id)
      .forEach((d) => {
        let sc: TGraphScale = d.useAdditionalScale && additionalScale ? additionalScale : scale;
        if (reference && additionalScale) sc = additionalScale;
        d.dataPoints.forEach((dp) => {
          const x = xPoint(dp.date);
          const y = yPoint(dp.value, sc, height);
          if (!(x || x === 0) || !(y || y === 0)) return;
          dps.push({
            id: id,
            x: x,
            y: y,
            value: dp.value,
            date: dp.date,
            legend: d.legend,
            title: dp.title,
            description: dp.description,
            alternativeDateString: dp.alternativeDateString,
            useAdditionalScale: d.useAdditionalScale,
            pointType: dp.pointType,
          });
        });
      });
    dataPoints[id] = dps;
  });
  const multipleDataPoints: IMultiplePoint[] = [];
  const allDataPoints: IPoint[] = [];
  Object.values(dataPoints).forEach((dps) => {
    allDataPoints.push(...dps);
  });

  allDataPoints.sort((dp1, dp2) => sortDate(dp1.date, dp2.date));

  const step = 12;

  // Hack to make seizures per day graph not merge datapoints
  if (
    !data.find((d) => d.linegraphProps?.defaultToZero || d.linegraphProps?.doNotMergeOverlappingDataPoints) &&
    allDataPoints.length > 1
  ) {
    // Iterate through all the dataPoints
    for (let i = 0; i < allDataPoints.length - 1; i++) {
      let dataPointsInsideSquare = 1;
      const tooltipContents: Array<{
        title: string;
        date?: Date;
        start?: Date;
        end?: Date;
        description: any;
        name?: string;
      }> = [];
      // Assign the next dataPoint's index into a variable named ix
      let ix = i + 1;
      // Find yStart and xStart values for the current dataPoint
      const yStart = Math.floor(allDataPoints[i].y / step) * step;
      const xStart = Math.floor(allDataPoints[i].x / step) * step;
      // Here we check all the dataPoints within xStart and xStart+step. If overlapping dataPoints are found, handle them here
      while (ix <= allDataPoints.length - 1 && allDataPoints[ix].x >= xStart && allDataPoints[ix].x < xStart + step) {
        // If datapoint already has been taken into action, skip.
        if (allDataPoints[ix].doNotDraw) {
          ix += 1;
          continue;
        }
        // If dataPoint with x value within range is found, mark it as doNotDraw
        if (allDataPoints[ix].y >= yStart && allDataPoints[ix].y < yStart + step) {
          allDataPoints[ix].doNotDraw = true;
          dataPointsInsideSquare += 1;
          tooltipContents.push({
            title: allDataPoints[ix]?.title ?? '',
            date: allDataPoints[ix]?.date,
            description: allDataPoints[ix]?.description,
            name: data.find((d) => d.id === allDataPoints[ix].id)?.legend,
          });
        }
        ix += 1;
      }
      // Here make the multipleDataPoint
      if (dataPointsInsideSquare > 1) {
        // If overlapping datapoints are found, push the current dataPoint's information to the tooltip content array
        tooltipContents.push({
          title: allDataPoints[i]?.title ?? '',
          date: allDataPoints[i]?.date,
          description: allDataPoints[i]?.description,
          name: data.find((d) => d.id === allDataPoints[i].id)?.legend,
        });
        allDataPoints[i].doNotDraw = true;
        multipleDataPoints.push({
          id: allDataPoints[i].id,
          n: dataPointsInsideSquare,
          x: xStart + step / 2,
          y: yStart + step / 2,
          tooltipContents: tooltipContents,
        });
      }
    }
  }

  const mergedDataPoints: { [key: string]: IPoint[] } = {};

  ids.forEach((id) => {
    mergedDataPoints[id] = allDataPoints.filter((dp) => dp.id === id);
  });

  const [showLine, setShowLine] = React.useState<number | null>(null);

  const sameColorSets =
    scaleId === 'tdcsTreatmentsPerWeek'
      ? [...new Set(Object.keys(mergedDataPoints).map((mdpKey) => mdpKey.split('-')[0] + mdpKey.split('-')[1]))]
      : undefined;

  return (
    <g>
      {Object.entries(mergedDataPoints).map(([key, points], i) => {
        let color: string;
        if (scaleId === 'tdcsTreatmentsPerWeek') {
          const usedKeyForColor = key.split('-')[0] + key.split('-')[1];
          let keyColorIndex = sameColorSets?.findIndex((s) => s === usedKeyForColor);
          if (keyColorIndex === undefined || keyColorIndex === -1) keyColorIndex = 0;
          color =
            Object.values(GraphColors)[
              (numberOfOtherGraphs * graphTypeIndex + keyColorIndex) % Object.values(GraphColors).length
            ];
        } else {
          color = graphSettings?.[key]
            ? GraphColors[graphSettings[key]]
            : Object.values(GraphColors)[
                (numberOfOtherGraphs * graphTypeIndex + i) % Object.values(GraphColors).length
              ];
        }

        if (reference) color = invertColor(color);
        const linegraphProps = data.find((d) => d.id === key)?.linegraphProps;
        const path: { path: string; color: string; strokeWidth: number } = {
          path: createPath(points, linegraphProps, height, width, xPoint),
          color: color,
          strokeWidth: linegraphProps?.stepLine ? 1.5 : 2.5,
        };
        return (
          <g key={key}>
            <g>
              {path.path && (
                <path d={path.path} stroke={path.color} strokeWidth={path.strokeWidth + 'px'} fill="none" opacity={1} />
              )}
            </g>

            {points
              .filter((p) => !p.doNotDraw)
              .map((p, i) => (
                <g key={`${key}${i}${p.x}${p.y}`}>
                  {((additionalScale && !additionalScale.dontDrawHelperLine) || reference) && (
                    <line
                      x1={p.x}
                      x2={p.useAdditionalScale || reference ? '100%' : 0}
                      y1={p.y}
                      y2={p.y}
                      stroke={'black'}
                      strokeWidth="0.5"
                      strokeDasharray={5}
                      opacity={showLine === i ? 1 : 0}
                    />
                  )}
                  <GraphTooltip
                    date={p.date}
                    name={p.legend}
                    title={p.title ?? p.value.toString()}
                    description={p.description ? <GraphTooltipDescription convertedData={p.description} /> : undefined}
                    content={
                      p.pointType === 'rect' ? (
                        <rect
                          key={`${p.x}${p.y}`}
                          x={p.x - (linegraphProps?.defaultToZero ? 3 : 6)}
                          y={p.y - (linegraphProps?.defaultToZero ? 3 : 6)}
                          width={linegraphProps?.hidePoints ? '2' : linegraphProps?.defaultToZero ? 6 : 12}
                          height={linegraphProps?.hidePoints ? '2' : linegraphProps?.defaultToZero ? 6 : 12}
                          fill={linegraphProps?.hidePoints ? 'transparent' : color}
                          onMouseEnter={
                            additionalScale && !additionalScale.dontDrawHelperLine ? () => setShowLine(i) : () => null
                          }
                          onMouseLeave={() => setShowLine(null)}
                        />
                      ) : (
                        <circle
                          key={`${p.x}${p.y}`}
                          cx={p.x}
                          cy={p.y}
                          r={linegraphProps?.hidePoints ? '2' : linegraphProps?.defaultToZero ? '3' : '6'}
                          fill={linegraphProps?.hidePoints ? 'transparent' : color}
                          onMouseEnter={
                            additionalScale && !additionalScale.dontDrawHelperLine ? () => setShowLine(i) : () => null
                          }
                          onMouseLeave={() => setShowLine(null)}
                        />
                      )
                    }
                    alternativeDateString={p.alternativeDateString}
                  />
                </g>
              ))}
          </g>
        );
      })}
      {multipleDataPoints.map((p, i) => {
        const linegraphProps = data.find((d) => d.id === p.id)?.linegraphProps;
        return (
          <MultipleGraphTooltip
            key={`${i}${p.n}${p.x}${p.y}`}
            data={p.tooltipContents}
            content={
              <g>
                <circle cx={p.x} cy={p.y} r={linegraphProps?.hidePoints ? '2' : '10'} fill="white" stroke="#8D8D8D" />
                <text
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fontWeight="bold"
                  fontSize="12"
                  fill={linegraphProps?.hidePoints ? 'transparent' : '#8D8D8D'}
                  y={p.y}
                  x={p.x}
                >
                  {p.n > 9 ? '9+' : `${p.n}`}
                </text>
              </g>
            }
            alternativeDateString={p.alternativeDateString}
          />
        );
      })}
    </g>
  );
};

interface IMultiplePoint {
  id: string;
  n: number;
  x: number;
  y: number;
  tooltipContents: Array<{
    title: string;
    date?: Date;
    start?: Date;
    end?: Date;
    description: any;
    name?: string;
  }>;
  alternativeDateString?: string;
}

interface IPoint {
  id: string;
  x: number;
  y: number;
  value: string | number;
  date: Date;
  legend?: string;
  title?: string;
  description?: IDataPoint['description'];
  doNotDraw?: boolean;
  alternativeDateString?: string;
  useAdditionalScale?: boolean;
  pointType?: 'rect' | 'circle';
}

interface IOwnProps {
  data: IData[];
  xPoint: (date: Date) => number | undefined;
  yPoint: (value: string | number, scale: TGraphScale, graphHeight: number) => number | undefined;
  height: number;
  width: number;
  scale: TGraphScale;
  additionalScale: TGraphScale | undefined;
  graphSettings: TGraphSettings['graphSettings'];
  reference?: true;
  numberOfOtherGraphs: number;
  graphTypeIndex: number;
  scaleId?: string; // Used to color ninmt tdcsTreatmentsPerWeek graph dataPoints
}

export default LineGraph;
