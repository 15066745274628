import * as React from 'react';

interface IOwnProps {
  strokeColor: string;
  strokeWidth: number;
  fillColor: string;
  large?: boolean;
  active?: boolean;
  priority?: 'low' | 'normal' | 'high' | undefined;
  addon?: boolean;
}

export const IconTechnicalIssue = ({
  strokeColor,
  strokeWidth,
  fillColor,
  active = false,
  priority = 'low',
  addon = false,
}: IOwnProps): JSX.Element => {
  const [hover, setHover] = React.useState<boolean>(false);

  const mouseEnter = (): void => {
    if (active) {
      setHover(true);
    }
  };

  const mouseLeave = (): void => {
    setHover(false);
  };

  return priority === 'low' ? (
    <g
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      transform={addon ? undefined : `translate(${hover ? -8.8 : -8}, ${hover ? -8.8 : -8}) scale(${hover ? 1.1 : 1})`}
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M 1 14.5 L 8 1 L 15 14.5 Z"
          x={0}
          y={0}
          width="16"
          height="16"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          fill={fillColor}
        />
      </svg>
    </g>
  ) : priority === 'normal' ? (
    <g
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      transform={addon ? undefined : `translate(${hover ? -8.8 : -8}, ${hover ? -8.8 : -8}) scale(${hover ? 1.1 : 1})`}
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M 1 14.5 L 8 1 L 15 14.5 Z"
          x={0}
          y={0}
          width="16"
          height="16"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          fill={fillColor}
        />
      </svg>
    </g>
  ) : (
    <g
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      transform={addon ? undefined : `translate(${hover ? -8.8 : -8}, ${hover ? -8.8 : -8}) scale(${hover ? 1.1 : 1})`}
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M 1 14.5 L 8 1 L 15 14.5 Z"
          x={0}
          y={0}
          width="16"
          height="16"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          fill={fillColor}
        />
      </svg>
    </g>
  );
};
