import { createTheme, PaletteColor } from '@mui/material/styles';
import colors from './colors';
import { screenBreakpoints, tileSettingsNew } from './componentTheme';

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },
  typography: {
    fontSize: 14, // Somehow this translates to 16px
    htmlFontSize: 10,
    fontFamily: ['Titillium Web'].join(','),
  },
  palette: {
    primary: {
      main: colors.primary,
      dark: colors.secondary,
      light: colors.tertiary,
    },
    common: {
      white: colors.white,
      black: colors.black,
    },
    grey: {
      200: colors.lightestGray,
      300: colors.lightGray,
      400: colors.mediumGray,
      500: colors.gray,
      700: colors.darkGray,
    },
    info: {
      main: colors.info.default,
      dark: colors.info.dark,
      light: colors.info.light,
    },
    warning: {
      main: colors.warning.default,
      dark: colors.warning.dark,
      light: colors.warning.light,
    },
  },
  breakpoints: {
    values: screenBreakpoints,
  },
  customComponents: {
    tile: {
      ...tileSettingsNew,
    },
  },
  customPalette: {
    myms: {
      primary: { main: colors.myms.primary },
    },
  },
});

export default theme;

type TFont = {
  size: string;
  color: string;
  secondaryColor?: string;
};

interface IComponents {
  tile: {
    width: string;
    height: string;
    margin: string;
    padding: string;
    overlay: { width: string; height: string; margin: string };
    icon: { width: string; height: string };
    header: { [key: string]: string | number };
    body: { [key: string]: string | number };
    bodyPadding: string;
    fonts: {
      header: TFont;
      diagnosisText: TFont;
      bigText: TFont;
      normalText: TFont;
      smallText: TFont;
      smallestText: TFont;
    };
  };
}

interface IPalette {
  myms: { primary: Partial<PaletteColor> };
}

declare module '@mui/system' {
  interface Theme {
    customComponents: IComponents;
    customPalette: IPalette;
  }
}

declare module '@mui/material' {
  interface Theme {
    customComponents: IComponents;
    customPalette: IPalette;
  }
  interface ThemeOptions {
    customComponents: IComponents;
    customPalette: IPalette;
  }
}
