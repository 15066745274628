import * as React from 'react';
import { Theme } from '@mui/material';
import { styled } from '@mui/system';
import { Refresh, Add, Remove } from '@mui/icons-material';
import { scaleTime, timeDay, timeMonth, timeWeek, timeYear } from 'd3';

import { IDashboardGraphProps, IGraphDimensions, TXTick } from 'Components/sq-graphics/interfaces';
import Draggable from 'Components/Draggable';
import { areDatesEqual } from 'Components/sq-graphics/util/checkers';
import { getTimeFrameLengthBasedOnDates, parseNewTimeframe } from 'Components/sq-graphics/util/calculators';
import { without } from 'ramda';

const disabledColor = '#9EBFD2';

const StyledContainer = styled('div')({
  position: 'sticky',
  top: 0,
  width: '100%',
  minWidth: '89.4rem',
  backgroundColor: '#045A8B',
  zIndex: 10,
  borderBottom: '0.3px solid #045A8B',
  boxSizing: 'border-box',
});

const StyledTimeframeSelectorWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hidden' && prop !== 'dimensions',
})(({ hidden, dimensions }: { hidden: boolean; dimensions: IGraphDimensions }) => ({
  height: `${dimensions.timeframeSelector.selectors.height}rem`,
  width: '100%',
  display: hidden ? 'none' : undefined,
  position: 'relative',
  overflow: 'visible',
  zIndex: 1,
}));

const StyledTimeframeLabelsWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'dimensions',
})(({ dimensions }: { dimensions: IGraphDimensions }) => ({
  height: `${dimensions.timeframeSelector.labels.height}rem`,
  width: '100%',
}));

const StyledWhiteArea = styled('div', {
  shouldForwardProp: (prop) => !['side', 'width', 'dimensions'].includes(typeof prop === 'string' ? prop : ''),
})(({ side, width, dimensions }: { side: 'right' | 'left'; width: number; dimensions: IGraphDimensions }) => ({
  width: `${width}rem`,
  height: `${dimensions.timeframeSelector.labels.height}rem`,
  backgroundColor: 'white',
  borderRadius: `${side === 'right' ? '7.5px' : '0px'} ${side === 'left' ? '7.5px' : '0px'} 0px 0px`,
  position: 'absolute',
  bottom: 0,
  left: side === 'left' ? `${0}rem` : undefined,
  right: side === 'right' ? `${0}rem` : undefined,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  boxSizing: 'border-box',
}));

const StyledTimeline = styled('div', {
  shouldForwardProp: (prop) => !['side', 'left', 'dimensions'].includes(typeof prop === 'string' ? prop : ''),
})(({ width, left, dimensions }: { width: number; left: number; dimensions: IGraphDimensions }) => ({
  width: `${width}rem`,
  position: 'absolute',
  left: `${left}rem`,
  height: `${dimensions.timeframeSelector.labels.height}rem`,
  backgroundColor: '#045A8B',
}));

const StyledArrow = styled('div', {
  shouldForwardProp: (prop) => prop !== 'side' && prop !== 'disabled',
})(({ side, disabled }: { side: 'left' | 'right'; disabled: boolean }) => ({
  height: '100%',
  width: '2rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  right: side === 'left' ? 0 : undefined,
  left: side === 'right' ? 0 : undefined,
  '&:hover': {
    opacity: disabled ? 1 : '0.8',
  },
}));

const StyledTimeframeTitle = styled('div')({
  fontSize: '1.6rem',
  color: 'white',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  position: 'relative',
  top: '0.5rem',
  userSelect: 'none',
  marginRight: '1rem',
});

const FilledArea = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ width }: { width?: number }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  width: width ? width + 'rem' : '2.5rem',
  height: '2.5rem',
  backgroundColor: 'white',
  borderRadius: '2rem',
  cursor: 'pointer',
}));

const FilledIconSecondary = styled('div', {
  shouldForwardProp: (prop) => !['width', 'height', 'disabled', 'theme'].includes(typeof prop === 'string' ? prop : ''),
})(({ width, height, disabled, ...props }: { width?: number; height?: number; disabled?: boolean }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: width ? width + 'rem' : '2.5rem',
  height: height ? height + 'rem' : '2.5rem',
  backgroundColor: disabled
    ? (props as { theme: Theme }).theme.palette.primary.light
    : (props as { theme: Theme }).theme.palette.primary.main,
  borderRadius: '2rem',
}));

const RefreshIcon = styled(Refresh)(({ theme }) => ({
  display: 'block',
  color: theme.palette.primary.main,
  width: '2.1rem',
  height: '2.1rem',
  transform: 'scaleX(-1) rotate(45deg)',
}));

const MinusIcon = styled(Remove, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})(({ disabled }: { disabled?: boolean }) => ({
  display: 'block',
  color: disabled ? 'white' : 'white',
  width: '100%',
  height: '100%',
  userSelect: 'none',
}));

const PlusIcon = styled(Add, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})(({ disabled }: { disabled?: boolean }) => ({
  display: 'block',
  color: disabled ? 'white' : 'white',
  width: '100%',
  height: '100%',
  userSelect: 'none',
}));

const HoverText = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 600,
  fontSize: '1.4rem',
  position: 'absolute',
  left: '3rem',
  top: '-0.1rem',
}));

const Arrow = ({
  side,
  onClick,
  disabled,
}: {
  side: 'left' | 'right';
  onClick: () => void;
  disabled: boolean;
}): JSX.Element =>
  side === 'left' ? (
    <svg
      onClick={onClick}
      style={{ cursor: disabled ? 'default' : 'pointer', userSelect: 'none' }}
      width="9"
      height="16"
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.07324e-07 8L9 0.205773L9 15.7942L1.07324e-07 8Z" fill={disabled ? disabledColor : '#045A8B'} />
    </svg>
  ) : (
    <svg
      onClick={onClick}
      style={{ cursor: disabled ? 'default' : 'pointer', userSelect: 'none' }}
      width="9"
      height="16"
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 8L2.00269e-07 15.7942L1.43787e-08 0.205772L9 8Z" fill={disabled ? disabledColor : '#045A8B'} />
    </svg>
  );

const StyledRulerHiderWrapper = styled('div')({
  display: 'flex' /*'none'*/,
  flexDirection: 'row',
  position: 'absolute',
  left: `52.5%`,
  alignItems: 'center',
  cursor: 'pointer',
});

const StyledHideRulerText = styled('div')({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  color: '#045A8B',
  marginLeft: '0.5rem',
  userSelect: 'none',
});

const Ruler = ({ xPos, setXPos, timeframe, timeframeWidth }: IRulerProps): JSX.Element => {
  const tf = React.useRef<[Date, Date]>(timeframe);
  const tfWidth = React.useRef<number>(timeframeWidth);

  React.useEffect(() => {
    const oldTf = tf.current;
    const oldTfWidth = tfWidth.current;
    if (oldTf != timeframe || oldTfWidth != timeframeWidth) {
      // Find the date by x-position
      let date: Date | undefined = undefined;
      if (oldTf != timeframe) {
        date = getScale(oldTf, [0, timeframeWidth]).invert(xPos);
      } else {
        date = getScale(timeframe, [0, oldTfWidth]).invert(xPos);
      }

      // Find the new x-position on the new timeframe
      let newXPos = getScale(timeframe, [0, timeframeWidth])(date);

      if (newXPos < 0) newXPos = 0;
      if (newXPos > timeframeWidth) newXPos = timeframeWidth;

      setXPos(newXPos);

      if (oldTf != timeframe) {
        tf.current = timeframe;
      } else {
        tfWidth.current = timeframeWidth;
      }
    }
  }, [timeframe, timeframeWidth, xPos]);

  // Function that handles movement of the ruler
  const onMove = (x: number): void => setXPos(x);

  // Function for getting x-position by date
  const getScale = (time: [Date, Date], range: [number, number]): any => {
    return scaleTime().domain(time).range(range);
  };

  const color = '#C20012';

  return (
    <div style={{ width: '100%', height: '0.1rem', marginTop: '-2.1rem', userSelect: 'none' }} id="ruler">
      <Draggable
        x={xPos}
        y={0}
        onMove={onMove}
        limits={{
          right: document.getElementById('ruler')?.getBoundingClientRect().right ?? 0,
          left: document.getElementById('ruler')?.getBoundingClientRect().left ?? 0,
        }}
        margins={{
          left: 0,
          right: timeframeWidth,
        }}
        parentId="ruler"
      >
        <svg overflow="visible" opacity="0.75" x="0" y="0" height="100%" width="100%">
          <rect y="0" x="-5" height="10" width="10" fill={color} opacity="1" style={{ cursor: 'move' }} />
          <svg xmlns="http://www.w3.org/2000/svg" width="10px" height="6px" viewBox="0 0 10 6" x="-5" y="10">
            <path fill={color} d="M 5 6 L 0.667969 0.375 L 9.332031 0.375 Z M 5 6 " style={{ cursor: 'move' }} />
          </svg>
          <line x1={0} x2={0} y1="16" y2={3000} stroke={color} strokeWidth={1} style={{ pointerEvents: 'none' }} />
        </svg>
      </Draggable>
    </div>
  );
};

const RulerHider = ({
  onClick,
  showRuler,
  text,
}: {
  onClick: () => void;
  showRuler: boolean;
  text: string;
}): JSX.Element => (
  <StyledRulerHiderWrapper onClick={onClick}>
    {showRuler ? (
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="11" height="11" fill="white" stroke="#8D8D8D" />
        <rect x="3" y="3" width="6" height="6" fill="#045A8B" />
      </svg>
    ) : (
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="11" height="11" fill="white" stroke="#8D8D8D" />
      </svg>
    )}
    <StyledHideRulerText>{text}</StyledHideRulerText>
  </StyledRulerHiderWrapper>
);

const XTicks = ({
  xTicks,
  timeframeWidth,
  dimensions,
}: {
  xTicks: IOwnProps['xTicks'];
  timeframeWidth: number;
  dimensions: IGraphDimensions;
}): JSX.Element => (
  <svg viewBox={`0 0 ${timeframeWidth * 10} ${dimensions.timeframeSelector.labels.height * 10}`}>
    {xTicks.map((xTick, i) => (
      <g key={xTick.value + ` #${i}`}>
        <line
          x1={xTick.x}
          x2={xTick.x}
          y1={'100%'}
          y2={xTick.priority === 'low' ? '50%' : '0%'}
          stroke="white"
          strokeWidth={1}
        />
        {xTick.priority !== 'low' ? (
          <text
            fontSize="12"
            fontWeight={xTick.priority === 'high' ? 'bold' : 'normal'}
            fill="white"
            y="50%"
            x={xTick.x + 5}
            dominantBaseline="middle"
            style={{ userSelect: 'none' }}
          >
            {xTick.value}
          </text>
        ) : null}
      </g>
    ))}
  </svg>
);

const StyledTimeframeSelectorsHiderWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  position: 'absolute',
  right: '52.5%',
  alignItems: 'center',
  cursor: 'pointer',
});

const StyledHideSelectorsText = styled('div')({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  color: '#045A8B',
  marginLeft: '0.5rem',
  userSelect: 'none',
});

const TimeframeSelectorsHider = ({
  onClick,
  hideSelectors,
  text,
}: {
  onClick: () => void;
  hideSelectors: boolean;
  text: string;
}): JSX.Element => {
  return (
    <StyledTimeframeSelectorsHiderWrapper onClick={onClick}>
      {!hideSelectors ? (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="11" height="11" fill="white" stroke="#8D8D8D" />
          <rect x="3" y="3" width="6" height="6" fill="#045A8B" />
        </svg>
      ) : (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="11" height="11" fill="white" stroke="#8D8D8D" />
        </svg>
      )}
      <StyledHideSelectorsText>{text}</StyledHideSelectorsText>
    </StyledTimeframeSelectorsHiderWrapper>
  );
};

const StyledTimeframeWrapper = styled('div')({
  position: 'relative',
  top: '0.5rem',
  paddingTop: '0.2rem',
  display: 'flex',
  flexDirection: 'row',
});

const StyledTimeframeText = styled('div')({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  userSelect: 'none',
  color: 'white',
});

const StyledTimeframe = ({ timeframe }: { timeframe: [Date, Date] }) => {
  //const [calendarOpen, setCalendarOpen] = React.useState<boolean>(false);
  const formatDate = (d: Date): string => `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
  return (
    <StyledTimeframeWrapper>
      <StyledTimeframeText>{`${formatDate(timeframe[0])} - ${formatDate(timeframe[1])}`}</StyledTimeframeText>
    </StyledTimeframeWrapper>
  );
};

const StyledDivider = styled('div')({
  height: '80%',
  position: 'absolute',
  top: '10%',
  left: '50%',
  width: '2px',
  backgroundColor: '#7FA9C3',
  borderRadius: '2px',
});

const isArrowDisabled = (side: 'left' | 'right', timeframe: [Date, Date], totalTimeframe: [Date, Date]): boolean => {
  switch (side) {
    case 'left': {
      return areDatesEqual(timeframe[0], totalTimeframe[0]);
    }
    case 'right': {
      return areDatesEqual(timeframe[1], totalTimeframe[1]);
    }
  }
};

const StyledWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ width }: { width: number }) => ({
  width: `${width}rem`,
  position: 'relative',
  overflow: 'visible',
  margin: 'auto',
}));

interface ISelectedTimeframeProps {
  timeframeWidth: number;
  xPoint: IOwnProps['xPoint'];
  timeframe: [Date, Date];
  totalTimeframe: [Date, Date];
}

const getTickInterval = (tf: [Date, Date]): any => {
  const dayDiff = Math.ceil(Math.abs(tf[1].getTime() - tf[0].getTime()) / (1000 * 3600 * 24));
  if (dayDiff < 8) return timeDay.every(1);
  if (dayDiff >= 8 && dayDiff < 40) return timeWeek.every(1);
  if (dayDiff < 400) return timeMonth.every(1);
  return timeYear.every(1);
};

const getTitle = (d: Date): string => {
  if (d.getMonth() === 0 && d.getDate() === 1) return `${d.getFullYear()}`;
  if (d.getDate() === 1) return `${d.getMonth() + 1}/${d.getFullYear()}`;
  return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
};

const getTotalTimeframeXTicks = (
  totalTimeframe: [Date, Date],
  timeframeWidth: number,
): { x: number; value: string }[] => {
  const xTicks: TXTick[] = [];

  // Define the function that is used to calculate the ticks
  const timeRange = scaleTime()
    .domain(totalTimeframe)
    .range([0, timeframeWidth * 10]);

  timeRange.ticks(getTickInterval(totalTimeframe) ?? 1).forEach((value: Date) => {
    const x = timeRange(value);
    if (typeof x !== 'number' || !(x || x === 0)) return;
    const title = getTitle(value);
    xTicks.push({ x: Math.round(x), value: title });
  });
  if (
    xTicks.length > 1 &&
    xTicks.every((xTick) => xTick.value.length === 4) &&
    !xTicks.find((xTick) => xTick.value === totalTimeframe[0].getFullYear().toString()) &&
    xTicks[0].x > 25
  ) {
    const x = timeRange(new Date(totalTimeframe[0].getFullYear(), 0, 1));
    if (x || x === 0) {
      xTicks.unshift({ x: Math.round(x), value: `${totalTimeframe[0].getFullYear()}` });
    }
  }

  return !timeRange ? xTicks : xTicks;
};

const SelectedTimeframe = ({
  timeframeWidth,
  xPoint,
  timeframe,
  totalTimeframe,
}: ISelectedTimeframeProps): JSX.Element => {
  const xStart = xPoint(timeframe[0], true);
  const xEnd = xPoint(timeframe[1], true);
  const xTicks = getTotalTimeframeXTicks(totalTimeframe, timeframeWidth);
  return (
    <svg viewBox={`0 0 ${timeframeWidth * 10} ${30}`} overflow="visible">
      {/** The darker bar below the white one */}
      <rect rx="2" ry="2" x="0" y="20" opacity="0.5" width={timeframeWidth * 10} height="8" fill="#B9D1DF" />
      {/** The white bar on top of the darker one */}
      {(xStart || xStart === 0) && (xEnd || xEnd === 0) && (
        <rect x={xStart} y="20" rx="2" ry="2" width={xEnd - xStart} height="8" fill="white" />
      )}
      {/** The line on top of which the ticks are drawn */}
      <line x1="0" x2={timeframeWidth * 10} y1="16" y2="16" stroke="#B9D1DF" opacity="0.5" strokeWidth="2" />
      {/** Draws white line on top of the line above */}
      {(xStart || xStart === 0) && (xEnd || xEnd === 0) && (
        <line x1={xStart} x2={xEnd} y1="16" y2="16" stroke="white" strokeWidth="2" />
      )}
      {/** The xTicks */}
      {xTicks.map((xTick, i) =>
        xTick.x >= 0 && xTick.x <= timeframeWidth * 10 ? (
          <line
            key={xTick.value + i}
            x1={xTick.x}
            x2={xTick.x}
            y1="8"
            y2="16"
            stroke={
              (xStart || xStart === 0) && (xEnd || xEnd === 0) && xTick.x >= xStart && xTick.x <= xEnd
                ? 'white'
                : '#B9D1DF'
            }
            opacity={(xStart || xStart === 0) && (xEnd || xEnd === 0) && xTick.x >= xStart && xTick.x <= xEnd ? 1 : 0.5}
            strokeWidth="2"
          />
        ) : null,
      )}
      {/** xTick titles */}
      {xTicks.map((xTick, i) => {
        // All the titles except the last one are drawn in between the current and the next xTick
        // The last title (current year(?)) is drawn in between the current xTick and end of timeframe area
        // If there is no enough space for the last tick, do not draw it
        let x =
          i < xTicks.length - 1
            ? (xTick.x + xTicks[i + 1].x) / 2
            : timeframeWidth * 10 - xTick.x > 30
              ? (timeframeWidth * 10 + xTick.x) / 2
              : undefined;
        if (!(x || x === 0)) return;
        // Since textAnchor is set to 'middle', values of x smaller than 15 are set into 15
        if (x < 15) x = 15;
        // THis makes the text not being drawn if there is no enough space for the first xTick title
        if (i === 0 && xTicks.length > 1 && xTicks[1].x < 30) x = undefined;
        if (!(x || x === 0)) return;
        return x || x === 0 ? (
          <text
            key={xTick.value + i}
            x={x}
            y="12"
            textAnchor="middle"
            fontSize="10"
            fontWeight="bold"
            opacity={(xStart || xStart === 0) && (xEnd || xEnd === 0) && x >= xStart && x <= xEnd ? 1 : 0.5}
            fill={(xStart || xStart === 0) && (xEnd || xEnd === 0) && x >= xStart && x <= xEnd ? 'white' : '#B9D1DF'}
          >
            {xTick.value}
          </text>
        ) : null;
      })}
      {/** Ticks in the beginning and the end of the horizontal line */}
      {/*
      <line
        x1="0"
        x2="0"
        y1="8"
        y2="17"
        stroke={(xStart || xStart === 0) && xStart <= 1 ? 'white' : '#B9D1DF'}
        opacity={(xStart || xStart === 0) && xStart <= 1 ? 1 : 0.5}
        strokeWidth="2"
      />
      <line
        x1={timeframeWidth * 10 - 1}
        x2={timeframeWidth * 10 - 1}
        y1="8"
        y2="17"
        stroke={(xEnd || xEnd === 0) && xEnd >= timeframeWidth * 10 - 1 ? 'white' : '#B9D1DF'}
        opacity={(xEnd || xEnd === 0) && xEnd >= timeframeWidth * 10 - 1 ? 1 : 0.5}
        strokeWidth="2"
    />*/}
    </svg>
  );
};

const TimeframeSelector = ({
  xTicks,
  timeframeWidth,
  metaLocalizations,
  timeframeSelectorsHiddenByDefault,
  timeframeLengthOptions,
  timeframe,
  totalTimeframe,
  timeframeLength,
  setTimeframeLength,
  shiftTimeframe,
  width,
  xPoint,
  dimensions,
  reloadGraph,
}: IOwnProps): JSX.Element => {
  const [hideSelectors, setSelectorsHidden] = React.useState<boolean>(timeframeSelectorsHiddenByDefault);
  const [showRuler, setRulerShowing] = React.useState<boolean>(false);
  // Variable for holding the x-position of the ruler
  const [xPos, setXPos] = React.useState<number>(0);

  const disablingTreshold = totalTimeframe[1].valueOf() - totalTimeframe[0].valueOf();
  const disabledArray: (string | null)[] = [];
  timeframeLengthOptions.forEach((stf) => {
    const potentialTimeframe = parseNewTimeframe(timeframe, totalTimeframe, stf);
    const disable = disablingTreshold < potentialTimeframe[1].valueOf() - potentialTimeframe[0].valueOf();
    disabledArray.push(disable ? stf : null);
  });

  const possibleTimeframeOptions = without(disabledArray, timeframeLengthOptions) as TTimeframeLengthOption[];
  const currentTimeFrameOptionIndex = possibleTimeframeOptions.findIndex((opt) => opt === timeframeLength);

  const customTimeframe =
    timeframeLength === 'custom' ? getTimeFrameLengthBasedOnDates(timeframe[0], timeframe[1], totalTimeframe) : null;
  const customTimeframeIndex = possibleTimeframeOptions.findIndex((opt) => opt === customTimeframe?.timeFrameLength);

  // Get next timeframe indexes for zoom buttons
  const zoomIndexes = {
    // Plus button
    in:
      timeframeLength === 'custom' &&
      customTimeframeIndex < possibleTimeframeOptions.length - 1 &&
      possibleTimeframeOptions.length !== 1
        ? customTimeframeIndex + 1 // When user selected timeframe, show button if there are shorter timeframes available
        : timeframeLength !== 'custom' && currentTimeFrameOptionIndex < possibleTimeframeOptions.length - 1
          ? currentTimeFrameOptionIndex + 1
          : null,

    // Minus button
    out:
      timeframeLength === 'custom' && customTimeframeIndex !== 0 && customTimeframeIndex !== -1
        ? customTimeframeIndex - 1
        : timeframeLength !== 'custom' && currentTimeFrameOptionIndex !== 0 && currentTimeFrameOptionIndex !== -1
          ? currentTimeFrameOptionIndex - 1 // When user selected timeframe, show button if there are longer timeframes available
          : timeframeLength === 'custom' && possibleTimeframeOptions.length === 1
            ? 0
            : null,
  };

  const [hoverState, setHoverState] = React.useState<'zoomIn' | 'zoomOut' | 'default' | null>(null);

  return (
    <StyledContainer>
      {/** Tää piirtää dashboardin ulkopuolelle valkoset palkit joissa on timeframen shiftaus jne */}
      <div
        style={{
          height: `${dimensions.timeframeSelector.labels.height}rem`,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          position: 'absolute',
          left: 0,
          bottom: 0,
        }}
      >
        <div style={{ height: '100%', backgroundColor: 'white', display: 'flex', flexGrow: 1 }} />
        <div style={{ height: '100%', display: 'flex', flexBasis: `${width}rem` }} />
        <div style={{ height: '100%', backgroundColor: 'white', display: 'flex', flexGrow: 1 }} />
      </div>
      <StyledWrapper width={width}>
        <StyledTimeframeSelectorWrapper hidden={hideSelectors} dimensions={dimensions}>
          <div style={{ position: 'absolute', display: 'flex', flexDirection: 'row', left: '0rem' }}>
            <StyledTimeframeTitle>{metaLocalizations.selectedTimeframe}</StyledTimeframeTitle>
            <StyledTimeframe timeframe={timeframe} />
          </div>
          <div
            style={{
              position: 'absolute',
              left: `${dimensions.leftColumn.width + dimensions.leftColumn.paddingLeft}rem`,
              width: `${timeframeWidth}rem`,
              height: `${dimensions.timeframeSelector.selectors.height}rem`,
            }}
          >
            <SelectedTimeframe
              timeframeWidth={timeframeWidth}
              xPoint={xPoint}
              timeframe={timeframe}
              totalTimeframe={totalTimeframe}
            />
          </div>
          <div
            style={{
              position: 'absolute',
              right: '5rem',
              alignItems: 'center',
              display: 'flex',
              height: '100%',
            }}
          >
            <FilledArea
              style={{ marginRight: '1rem' }}
              onClick={reloadGraph}
              onMouseOver={() => setHoverState('default')}
              onMouseLeave={() => setHoverState(null)}
            >
              <RefreshIcon />
            </FilledArea>
            <FilledArea width={5}>
              <FilledIconSecondary
                width={1.6}
                height={1.6}
                disabled={zoomIndexes.out === null}
                onClick={
                  zoomIndexes.out !== null
                    ? () => setTimeframeLength(possibleTimeframeOptions[zoomIndexes.out!])
                    : undefined
                }
                onMouseOver={() => setHoverState('zoomOut')}
                onMouseLeave={() => setHoverState(null)}
              >
                <MinusIcon />
              </FilledIconSecondary>
              <FilledIconSecondary
                width={1.6}
                height={1.6}
                disabled={zoomIndexes.in === null}
                onClick={
                  zoomIndexes.in !== null
                    ? () => setTimeframeLength(possibleTimeframeOptions[zoomIndexes.in!])
                    : undefined
                }
                onMouseOver={() => setHoverState('zoomIn')}
                onMouseLeave={() => setHoverState(null)}
              >
                <PlusIcon />
              </FilledIconSecondary>
            </FilledArea>
          </div>
        </StyledTimeframeSelectorWrapper>
        <StyledTimeframeLabelsWrapper dimensions={dimensions}>
          <StyledWhiteArea
            width={dimensions.leftColumn.width + dimensions.leftColumn.paddingLeft}
            side="left"
            dimensions={dimensions}
          >
            <div style={{ position: 'relative', height: '100%', width: '100%' }}>
              <TimeframeSelectorsHider
                hideSelectors={hideSelectors}
                onClick={(): void => setSelectorsHidden((prev) => !prev)}
                text={metaLocalizations.hideTimeframeSelectors}
              />
              <StyledDivider />
              <RulerHider
                onClick={(): void => setRulerShowing(!showRuler)}
                showRuler={showRuler}
                text={metaLocalizations.hideRuler}
              />
              {/** Move left arrow */}
              <StyledArrow side="left" disabled={isArrowDisabled('left', timeframe, totalTimeframe)}>
                <Arrow
                  onClick={(): void => {
                    if (isArrowDisabled('left', timeframe, totalTimeframe)) return;
                    shiftTimeframe('left');
                  }}
                  side="left"
                  disabled={isArrowDisabled('left', timeframe, totalTimeframe)}
                />
              </StyledArrow>
            </div>
          </StyledWhiteArea>
          <StyledTimeline
            left={dimensions.leftColumn.width + dimensions.leftColumn.paddingLeft}
            width={timeframeWidth}
            dimensions={dimensions}
          >
            <XTicks timeframeWidth={timeframeWidth} xTicks={xTicks} dimensions={dimensions} />
            {showRuler && (
              <Ruler xPos={xPos} setXPos={setXPos} timeframe={timeframe} timeframeWidth={timeframeWidth * 10} />
            )}
          </StyledTimeline>
          <StyledWhiteArea
            width={dimensions.rightColumn.width + dimensions.rightColumn.paddingRight}
            side="right"
            dimensions={dimensions}
          >
            <div style={{ position: 'relative', height: '100%', width: '100%' }}>
              {/** Move right arrow */}
              <StyledArrow side="right" disabled={isArrowDisabled('right', timeframe, totalTimeframe)}>
                <Arrow
                  onClick={(): void => {
                    if (isArrowDisabled('right', timeframe, totalTimeframe)) return;
                    shiftTimeframe('right');
                  }}
                  side="right"
                  disabled={isArrowDisabled('right', timeframe, totalTimeframe)}
                />
              </StyledArrow>
            </div>
            <HoverText>
              {hoverState === 'zoomIn'
                ? metaLocalizations.zoomIn
                : hoverState === 'zoomOut'
                  ? metaLocalizations.zoomOut
                  : hoverState === 'default'
                    ? metaLocalizations.default
                    : undefined}
            </HoverText>
          </StyledWhiteArea>
        </StyledTimeframeLabelsWrapper>
      </StyledWrapper>
    </StyledContainer>
  );
};

interface IRulerProps {
  xPos: number;
  setXPos: React.Dispatch<React.SetStateAction<number>>;
  timeframe: [Date, Date];
  timeframeWidth: number;
}

interface IOwnProps {
  xTicks: TXTick[];
  timeframeWidth: number;
  metaLocalizations: IDashboardGraphProps['metaLocalizations'];
  timeframeSelectorsHiddenByDefault: boolean;
  timeframeLengthOptions: TTimeframeLengthOption[];
  timeframe: [Date, Date];
  totalTimeframe: [Date, Date];
  timeframeLength: TTimeframeLengthOption;
  setTimeframeLength: (timeframeLength: TTimeframeLengthOption) => void;
  shiftTimeframe: (direction: 'right' | 'left') => void;
  width: number;
  xPoint: (d: Date | undefined, useTotalTimeframe?: true) => number | undefined;
  dimensions: IGraphDimensions;
  reloadGraph: () => void;
}

export default React.memo(TimeframeSelector);
