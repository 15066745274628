import { Theme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { take } from 'ramda';
import * as React from 'react';

import { RenderValue } from './common/commonComponents';
import { dateCell, height, table, titleCell, valueCell } from './common/commonStyles';
import { IData } from './definitions';

const DataTable = styled('table')(table);

const DataRow = styled('tr')(({ theme }) => ({
  height: height,
  ':nth-of-type(odd)': {
    backgroundColor: theme.palette.grey[200],
  },
  verticalAlign: 'baseline',
  lineHeight: '2rem',
}));

const DataCellDate = styled('td')(({ theme }: { theme?: Theme }) => ({
  ...dateCell(theme),
  padding: '0.7rem 0 0.7rem 0.6rem',
}));

const DataCellTitle = styled('td', {
  shouldForwardProp: (prop) => prop !== 'hasValue',
})(({ theme, hasValue }: { theme?: Theme; hasValue: boolean }) => ({
  ...titleCell(theme, true, hasValue),
  padding: hasValue ? '0.7rem 0 0.7rem 0' : '0.7rem 0.6rem 0.7rem 0',
}));

const DataCellValue = styled('td')(({ theme }: { theme?: Theme }) => ({
  ...valueCell(theme),
  padding: '0.7rem 0.6rem 0.7rem 0',
  whiteSpace: 'nowrap' as const,
}));

const RecentListMultipleRows = ({ data, itemsCanFitAmount, styles }: IRecentListMultipleRows) => {
  const shownItems = take(itemsCanFitAmount, data);
  const renderValueCol = data.some((d) => d.endDate || d.value);
  return (
    <DataTable>
      <tbody>
        {shownItems.map((d) => (
          <DataRow key={d.key}>
            <DataCellDate style={styles?.date}>{d.date}</DataCellDate>
            <DataCellTitle hasValue={!!d.value} style={styles?.title}>
              {d.title}
            </DataCellTitle>
            {renderValueCol && (
              <DataCellValue>
                <RenderValue value={d.value} endDate={d.endDate} />
              </DataCellValue>
            )}
          </DataRow>
        ))}
      </tbody>
    </DataTable>
  );
};

export interface IRecentListMultipleRows {
  type: 'recentListMultipleRows';
  data: Array<IData>;
  itemsCanFitAmount: number;
  styles?: { date?: React.CSSProperties; title?: React.CSSProperties; value?: React.CSSProperties };
}

export default RecentListMultipleRows;
