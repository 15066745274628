import { createAction, createReducer } from '@reduxjs/toolkit';
import { IDelegate } from './util';

const LOAD_DATA = 'neuro-ui/patient/LOAD_DATA';
const CHANGE_DATA = 'neuro-ui/patient/CHANGE_DATA';
const CLEAR_DATA = 'neuro-ui/patient/CLEAR_DATA';
const CHANGE_EXITUS = 'neuro-ui/patient/CHANGE_EXITUS';

const LOAD_GRANTS = 'neuro-ui/patient/LOAD_GRANTS';
const CREATE_GRANT = 'neuro-ui/patient/CREATE_GRANT';
const UPDATE_GRANT = 'neuro-ui/patient/UPDATE_GRANT';
const DELETE_GRANT = 'neuro-ui/patient/DELETE_GRANT';

const LOAD_DELEGATES = 'neuro-ui/patient/LOAD_DELEGATES';

const loadDataAction = createAction(LOAD_DATA, (data: IPatientUI) => {
  return {
    payload: { data },
  };
});

const changeDataAction = createAction(CHANGE_DATA, (data: Partial<IPatientUI>) => {
  return {
    payload: { data },
  };
});

const clearDataAction = createAction(CLEAR_DATA, () => {
  return {
    payload: null,
  };
});

const changeExitusAction = createAction(CHANGE_EXITUS, (data: TExitusUI) => {
  return {
    payload: { data },
  };
});

const loadGrantsAction = createAction(LOAD_GRANTS, (grantList: TNeuroGrant[]) => {
  return {
    payload: { grantList },
  };
});
const createGrantAction = createAction(CREATE_GRANT, (grant: TNeuroGrant) => {
  return {
    payload: { grant },
  };
});
const updateGrantAction = createAction(UPDATE_GRANT, (grant: TNeuroGrant) => {
  return {
    payload: { grant },
  };
});
const deleteGrantAction = createAction(DELETE_GRANT, (id: string) => {
  return {
    payload: { id },
  };
});

const loadDelegatesAction = createAction(LOAD_DELEGATES, (delegates: IDelegate[]) => {
  return {
    payload: { delegates },
  };
});

export const reduxActions = {
  loadData: loadDataAction,
  changeData: changeDataAction,
  clearData: clearDataAction,
  changeExitus: changeExitusAction,
  loadGrants: loadGrantsAction,
  createGrant: createGrantAction,
  updateGrant: updateGrantAction,
  deleteGrant: deleteGrantAction,
  loadDelegates: loadDelegatesAction,
};

const initialState: IPatientStore = { data: undefined, grants: undefined, delegates: undefined };

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loadDataAction, (state, action) => {
      state.data = action.payload.data;
    })
    .addCase(changeDataAction, (state, action) => {
      state.data = { ...(state.data as IPatientUI), ...action.payload.data };
    })
    .addCase(clearDataAction, () => {
      return { data: undefined, grants: undefined };
    })
    .addCase(changeExitusAction, (state, action) => {
      state.data = { ...(state.data as IPatientUI), ...action.payload.data };
    })
    .addCase(loadGrantsAction, (state, action) => {
      state.grants = action.payload.grantList;
    })
    .addCase(createGrantAction, (state, action) => {
      state.grants = [...(state.grants || []), action.payload.grant];
    })
    .addCase(updateGrantAction, (state, action) => {
      const updatedGrant = action.payload.grant;
      const grants = (state.grants || []).map((g) => (g.id === updatedGrant.id ? updatedGrant : g));
      state.grants = grants;
    })
    .addCase(deleteGrantAction, (state, action) => {
      const grants = (state.grants || []).filter((g) => g.id !== action.payload.id);
      state.grants = grants;
    })
    .addCase(loadDelegatesAction, (state, action) => {
      state.delegates = action.payload.delegates;
    })
    .addDefaultCase((state) => {
      return state;
    });
});

export default reducer;
