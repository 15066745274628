import * as React from 'react';
import { IDashboardGraphProps, IGraphDimensions, TGraphMenuTheme } from '../../interfaces';
import { getGraphMenuTheme } from 'Components/sq-graphics/config';
import { isDisabledGraphMenu, isPinnableGraphMenu } from 'Components/sq-graphics/util/checkers';
import { GraphTooltip } from '../GraphTooltip';
import { FormattedMessage } from 'react-intl';
import colors from '../../../../config/theme/colors';
import { ColorBox, PanelArrow, StyledDivider } from '../AddonArea/Components';
import { Radio } from '@mui/material';
import { styled } from '@mui/system';
import { parseDefaultLeftMenu } from 'Components/sq-graphics/util/validators';

const StyledRadioContainer = styled('div')({
  lineHeight: '1rem', // Helps align radio vertically
  '& .MuiRadio-root': {
    width: '1.5rem',
    height: '1.5rem',
  },
  '& .MuiSvgIcon-root': {
    width: '1.5rem',
    height: '1.5rem',
  },
});

const StyledGraphMenuItem = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'disabled',
})((props: { selected: boolean; disabled: boolean }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '2.3rem',
  width: '100%',
  overflow: 'hidden',
  backgroundColor: 'white',
  cursor: !props.disabled ? 'pointer' : 'normal',
  alignItems: 'center',
  padding: '0.2rem 0',
}));

const PinIconWrapper = styled('div')({
  height: '100%',
  width: '1.8rem', // Centered vertically with radio buttons
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledGraphMenuTitle = styled('div', {
  shouldForwardProp: (prop) =>
    !['selected', 'graphTheme', 'disabled', 'dimensions'].includes(typeof prop === 'string' ? prop : ''),
})(
  ({
    selected,
    graphTheme,
    disabled,
    dimensions,
  }: {
    selected: boolean;
    graphTheme: TGraphMenuTheme;
    disabled: boolean;
    dimensions: IGraphDimensions;
  }) => ({
    fontSize: '1.4rem',
    height: '100%',
    width: `${dimensions.leftColumn.width - 5.5}rem`,
    fontWeight: selected ? 600 : 400,
    color: disabled ? 'darkgray' : graphTheme.color.default,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    userSelect: 'none',
    paddingLeft: '1rem',
    lineHeight: '2.3rem',
  }),
);

interface IGraphMenuItemProps {
  title: string;
  k: string;
  disabled: boolean;
  selected: boolean;
  pinnable: boolean;
  pinned: boolean;
  setLeftMenu: (key: string) => void;
  setReferenceGraphData: (key: string) => void;
  theme: TGraphMenuTheme;
  dimensions: IGraphDimensions;
}

interface IPinIconProps {
  theme: TGraphMenuTheme;
}

const PinIcon = ({ theme }: IPinIconProps): JSX.Element => {
  return (
    <PinIconWrapper>
      <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.75 1.5V4.625C5.75 5.325 5.98125 5.975 6.375 6.5H2.625C3.03125 5.9625 3.25 5.3125 3.25 4.625V1.5H5.75ZM7.625 0.25H1.375C1.03125 0.25 0.75 0.53125 0.75 0.875C0.75 1.21875 1.03125 1.5 1.375 1.5H2V4.625C2 5.6625 1.1625 6.5 0.125 6.5V7.75H3.85625V12.125L4.48125 12.75L5.10625 12.125V7.75H8.875V6.5C7.8375 6.5 7 5.6625 7 4.625V1.5H7.625C7.96875 1.5 8.25 1.21875 8.25 0.875C8.25 0.53125 7.96875 0.25 7.625 0.25Z"
          fill={theme.color.default}
        />
      </svg>
    </PinIconWrapper>
  );
};

const SelectedRect = ({ theme, selected, disabled }: ISelectedRectProps): JSX.Element => {
  return (
    <ColorBox
      color={disabled || !selected ? 'lightgray' : theme.color.default}
      fill={selected ? theme.color.light : 'white'}
    />
  );
};

interface ISelectedRectProps {
  theme: TGraphMenuTheme;
  selected: boolean;
  disabled: boolean;
}

const StyledContainer = styled('div')({
  height: 'auto',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  position: 'relative',
});

const GroupWrapperChildren = styled('div', {
  shouldForwardProp: (prop) => prop !== 'dimensions',
})(({ dimensions }: { dimensions: IGraphDimensions }) => ({
  padding: '0 0.5rem 0 1rem',
  width: `calc(100% - ${dimensions.addons.divider.width}rem)`,
  '& > div:not(:last-child)': {
    borderBottom: `1px solid ${colors.appBlue.lightest}`,
  },
}));

// Wrap group with a left side "divider" bar
const GraphGroupWrapper = ({ children, dimensions, topMargin, theme }: IGraphGroupWrapperProps) => {
  return (
    <div style={{ height: 'auto', display: 'flex', flexDirection: 'row', marginTop: topMargin ? '1rem' : undefined }}>
      <StyledDivider color={theme?.color.default ?? colors.appBlue.default} dimensions={dimensions} />

      <GroupWrapperChildren dimensions={dimensions}>{children}</GroupWrapperChildren>
    </div>
  );
};
interface IGraphGroupWrapperProps {
  children: JSX.Element;
  dimensions: IGraphDimensions;
  topMargin: boolean;
  theme?: TGraphMenuTheme;
}

const GraphMenuHeader = ({ title, theme, open, setOpen, dimensions }: IGraphMenuHeaderProps) => (
  <div style={{ display: 'flex' }} onClick={setOpen}>
    {typeof open === 'boolean' && (
      <div style={{ position: 'relative', top: '-0.35rem', cursor: 'pointer' }}>
        <PanelArrow open={open} />
      </div>
    )}
    <span
      style={{
        paddingLeft: '0.7rem',
        fontSize: '1.4rem',
        fontWeight: 'bold',
        color: theme.color.default,
        width: `${dimensions.leftColumn.width - 5.5}rem`,
        cursor: 'pointer',
      }}
    >
      {title}
    </span>
    {open && (
      <span>
        <PinIcon theme={theme} />
      </span>
    )}
  </div>
);
interface IGraphMenuHeaderProps {
  title: string;
  theme: TGraphMenuTheme;
  open?: boolean;
  setOpen?: () => void;
  dimensions: IGraphDimensions;
}
const GraphMenuItemComponent = ({
  disabled,
  selected,
  setLeftMenu,
  theme,
  pinnable,
  pinned,
  setReferenceGraphData,
  title,
  k,
  dimensions,
}: IGraphMenuItemProps) => (
  <StyledGraphMenuItem
    disabled={disabled}
    onClick={(): void => {
      if (disabled) return;
      setLeftMenu(k);
    }}
    selected={selected}
  >
    <SelectedRect theme={theme} disabled={disabled} selected={selected} />

    <StyledGraphMenuTitle disabled={disabled} graphTheme={theme} selected={selected} dimensions={dimensions}>
      {title}
    </StyledGraphMenuTitle>
    {pinnable && !selected && !disabled && (
      <StyledRadioContainer>
        <Radio
          checked={pinned}
          onClick={(e: React.MouseEvent): void => {
            setReferenceGraphData(k);
            e.stopPropagation();
          }}
          size="small"
          style={{ color: theme.color.default }}
        />
      </StyledRadioContainer>
    )}
  </StyledGraphMenuItem>
);

const GraphMenuItem = ({ k, ...props }: IGraphMenuItemProps): JSX.Element => {
  if (k === 'ledd') {
    return (
      <GraphTooltip
        title=""
        description={
          <React.Fragment>
            <div>
              <div style={{ fontSize: 16, fontWeight: 800 }}>{<FormattedMessage id="graph.ledd" />}</div>
              <div style={{ fontWeight: 600 }}>{<FormattedMessage id="graph.leddOpts.headerLevodopa" />}</div>
              <div>{<FormattedMessage id="graph.leddOpts.levodopa" />}</div>
              <div>{<FormattedMessage id="graph.leddOpts.ctrReleaseLevodpa" />}</div>
              <div>{<FormattedMessage id="graph.leddOpts.duodopa" />}</div>
              <div style={{ fontWeight: 600 }}>{<FormattedMessage id="graph.leddOpts.headerComt" />}</div>
              <div>{<FormattedMessage id="graph.leddOpts.entacapone" />}</div>
              <div>{<FormattedMessage id="graph.leddOpts.tolcapone" />}</div>
              <div>{<FormattedMessage id="graph.leddOpts.opicapone" />}</div>
              <div style={{ fontWeight: 600 }}>{<FormattedMessage id="graph.leddOpts.nonErgotDerivedHeader" />}</div>
              <div>{<FormattedMessage id="graph.leddOpts.pramipexole" />}</div>
              <div>{<FormattedMessage id="graph.leddOpts.ropinirole" />}</div>
              <div>{<FormattedMessage id="graph.leddOpts.rotigotine" />}</div>
              <div style={{ fontWeight: 600 }}>{<FormattedMessage id="graph.leddOpts.ergotDerivedHeader" />}</div>
              <div>{<FormattedMessage id="graph.leddOpts.bromocriptine" />}</div>
              <div>{<FormattedMessage id="graph.leddOpts.cabergoline" />}</div>
              <div style={{ fontWeight: 600 }}>{<FormattedMessage id="graph.leddOpts.maobHeader" />}</div>
              <div>{<FormattedMessage id="graph.leddOpts.selegiline" />}</div>
              <div>{<FormattedMessage id="graph.leddOpts.rasagiline" />}</div>
              <div>{<FormattedMessage id="graph.leddOpts.safinamide" />}</div>
              <div style={{ fontWeight: 600 }}>{<FormattedMessage id="graph.leddOpts.other" />}</div>
              <div>{<FormattedMessage id="graph.leddOpts.amantadine" />}</div>
              <div>
                {<FormattedMessage id="graph.leddOpts.apomorphine" />}
                &nbsp; &nbsp;
              </div>
            </div>
          </React.Fragment>
        }
        content={
          <div>
            <GraphMenuItemComponent k={k} {...props} />
          </div>
        }
      />
    );
  }
  return <GraphMenuItemComponent k={k} {...props} />;
};

const GraphMenu = ({
  graphData,
  graphDataGroups,
  graphMenuSettings,
  selLeftMenu,
  setLeftMenu,
  selRefGraphs,
  setReferenceGraphData,
  referenceGraphsBelowEachOther,
  dimensions,
}: IOwnProps): JSX.Element => {
  const _class = Object.keys(graphData).find((k) => !!graphData?.[k]?.[selLeftMenu]) ?? 'common';

  const firstOpenGroup = graphDataGroups ? Object.keys(graphDataGroups).find((g) => graphDataGroups?.[g].open) : null;

  /**
   * Set open groups based on the open setting,
   * unless onlyOneOpen has been set in graphMenuSettings, then set first open group as the only one open
   */
  const [openGroups, setOpenGroups] = React.useState<{ [key: string]: { open?: boolean } }>(
    firstOpenGroup && graphMenuSettings?.onlyOneOpen
      ? { [firstOpenGroup]: { open: true } }
      : graphDataGroups
        ? graphDataGroups
        : {},
  );

  const getTheme = (group: string) => getGraphMenuTheme(graphDataGroups?.[group]?.theme || undefined);

  // Open selfReporting-menugroup on first render if first found graphData is part of selfReporting
  React.useEffect(() => {
    const selectedLeftMenu = selLeftMenu ?? parseDefaultLeftMenu(graphData);

    const shouldOpenSelfreporting =
      graphData && graphData.selfReporting && Object.keys(graphData.selfReporting).includes(selectedLeftMenu);

    const shouldOpenClinicalMetersPap =
      graphData && graphData.clinicalMetersPap && Object.keys(graphData.clinicalMetersPap).includes(selectedLeftMenu);

    if (shouldOpenSelfreporting && graphMenuSettings?.onlyOneOpen) {
      setOpenGroups({ selfReporting: { open: true } });
    } else if (shouldOpenClinicalMetersPap && graphMenuSettings?.onlyOneOpen) {
      setOpenGroups({ clinicalMetersPap: { open: true } });
    } else if (shouldOpenClinicalMetersPap || shouldOpenSelfreporting) {
      setOpenGroups({
        ...openGroups,
        selfReporting: { open: shouldOpenSelfreporting },
        clinicalMetersPap: { open: shouldOpenClinicalMetersPap },
      });
    }
  }, []);

  const setOpenGroupHandler = (group: string) =>
    graphMenuSettings?.onlyOneOpen
      ? setOpenGroups({ [group]: { open: true } })
      : setOpenGroups({ ...openGroups, [group]: { open: !openGroups[group].open } });

  const groupIsOpen = (group: string) => !!openGroups[group]?.open;

  // Selected graph, or if for some reason its undefined, then select the first graph from common
  const selectedGraph = selLeftMenu || ('common' in graphData && Object.keys(graphData['common'])?.[0]) || '';
  return (
    <StyledContainer>
      {graphDataGroups
        ? Object.keys(graphDataGroups).map((group, i) => {
            if (Object.entries(graphData[group]).length === 0) return <div key={i}></div>;
            return (
              <GraphGroupWrapper key={group} dimensions={dimensions} topMargin={i > 0} theme={getTheme(group)}>
                <>
                  {graphDataGroups[group].groupHeader && (
                    <GraphMenuHeader
                      title={graphDataGroups[group].groupHeader ?? ''}
                      theme={getTheme(group)}
                      open={groupIsOpen(group)}
                      setOpen={() => setOpenGroupHandler(group)}
                      dimensions={dimensions}
                    />
                  )}
                  {groupIsOpen(group) &&
                    Object.entries(graphData[group]).map(([k, data]) => {
                      return (
                        <GraphMenuItem
                          title={data.title}
                          key={k}
                          k={k}
                          disabled={isDisabledGraphMenu(data)}
                          selected={k === selectedGraph}
                          pinnable={isPinnableGraphMenu(
                            referenceGraphsBelowEachOther,
                            data,
                            graphData[_class][selectedGraph],
                          )}
                          pinned={!!selRefGraphs.includes(k)}
                          setLeftMenu={setLeftMenu}
                          setReferenceGraphData={setReferenceGraphData}
                          theme={getTheme(group)}
                          dimensions={dimensions}
                        />
                      );
                    })}
                </>
              </GraphGroupWrapper>
            );
          })
        : Object.keys(graphData).map((key: string, i: number) => {
            if (!Object.keys(graphData[key]).length) return;
            return (
              <GraphGroupWrapper key={key} dimensions={dimensions} topMargin={i > 0}>
                <>
                  {key !== 'common' && <GraphMenuHeader title={key} theme={getTheme(key)} dimensions={dimensions} />}
                  {Object.entries(graphData[key]).map(([k, data]) => {
                    return (
                      <GraphMenuItem
                        title={data.title}
                        key={k}
                        k={k}
                        disabled={isDisabledGraphMenu(data)}
                        selected={k === selectedGraph}
                        pinnable={isPinnableGraphMenu(
                          referenceGraphsBelowEachOther,
                          data,
                          graphData[_class][selectedGraph],
                        )}
                        pinned={!!selRefGraphs.includes(k)}
                        setLeftMenu={setLeftMenu}
                        setReferenceGraphData={setReferenceGraphData}
                        theme={getTheme(key)}
                        dimensions={dimensions}
                      />
                    );
                  })}
                </>
              </GraphGroupWrapper>
            );
          })}
    </StyledContainer>
  );
};

interface IOwnProps {
  graphData: IDashboardGraphProps['graphData'];
  graphDataGroups: IDashboardGraphProps['graphDataGroups'];
  graphMenuSettings: IDashboardGraphProps['graphMenuSettings'];
  selLeftMenu: string;
  setLeftMenu: (key: string) => void;
  selRefGraphs: string[];
  setReferenceGraphData: (key: string) => void;
  referenceGraphsBelowEachOther: boolean;
  dimensions: IGraphDimensions;
}

export default GraphMenu;
