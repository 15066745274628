import { calculatePartialDateDiffenceDuration } from 'neuro-utils';
import { ClinicalArchive } from '.';
import { LoadableDocConfig } from '../generalSettings';

export const epilepsyDocumentTypes: LoadableDocConfig[] = [
  { name: 'diagnosis', subTypes: ['diagnosis', 'seizureType', 'syndrome', 'etiology'] },
  { name: 'seizure', subTypes: ['seizure', 'seizureFreePeriod'] },
  { name: 'otherSymptoms' },
  { name: 'functionalPerformance', subTypes: ['modifiedRankinScale', 'modifiedChildrensGlobalAssessmentScale'] },
  { name: 'medication', subTypes: ['medication', 'adverseEffect'] },
  { name: 'dbs' },
  { name: 'vns' },
  { name: 'surgicalTreatment' },
  { name: 'dietTherapy', subTypes: ['diet'] },
  { name: 'comorbidity', subTypes: ['comorbidity', 'pregnancy', 'contraception'], title: 'otherHealth' },
  { name: 'background', subTypes: ['background', 'measurement'] },
  { name: 'myService', title: 'titleShort' },
];

export const epilepsyGraphDocTypes = [
  'diagnosis',
  'medication',
  'adverseEffect',
  'contraception',
  'pregnancy',
  'diet',
  'otherSymptoms',
  'surgicalTreatment',
  'modifiedRankinScale',
  'modifiedChildrensGlobalAssessmentScale',
  'seizure',
  'seizureFreePeriod',
  'dbs',
  'vns',
];

export const epilepsyAlerts: IAlert[] = [
  {
    // Notification to the user if the estimated time to replace the VNS generator is less than one year.
    content: 'alerts.epilepsy.vnsOneYear',
    id: 'epilepsyVnsOneYear',
    severity: 'warning',
    popup: true,
    rule: (docs?: document[]): boolean => {
      // Find first dbs document (docs should be sorted descending by commitDate)
      const vnsDoc = ((docs || []).find((d) => d._type === 'vns') as IVNS) || {};
      const generatorChangeDate = vnsDoc.generators?.[0]?.generatorNextChangeDate;
      if (!generatorChangeDate) return false;
      const duration = calculatePartialDateDiffenceDuration('days', generatorChangeDate);
      return vnsDoc.status !== 'ended' && Math.abs(duration.days) < 365;
    },
  },
  {
    // Notification to the user if the estimated time to replace the DBS generator is less than one year.
    content: 'alerts.parkinson.dbsOneYear',
    id: 'epilepsyDbsOneYear',
    severity: 'warning',
    popup: true,
    rule: (docs?: document[]): boolean => {
      // Find first dbs document (docs should be sorted descending by commitDate)
      const dbsDoc = ((docs || []).find((d) => d._type === 'dbs') as IDBS) || {};
      const generatorChangeDate = dbsDoc.generators?.[0]?.generatorNextChangeDate;
      if (!generatorChangeDate) return false;
      const duration = calculatePartialDateDiffenceDuration('days', generatorChangeDate);
      return !dbsDoc.hasEnded?.includes(true) && Math.abs(duration.days) < 365;
    },
  },
];

export const epilepsyClinicalArchiveTypes: readonly ClinicalArchive[] = ['NEU'] as const;
