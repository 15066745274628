import { Badge, styled } from '@mui/material';
import Icon from 'Components/_NewElements/Icon';
import ActionButtonRounded, { IActionButtonRounded } from 'Components/ActionButtonRounded';
import DocumentCreationButton from 'Components/DocumentCreationButton';
import { Container, Item } from 'Components/Grid';
import DeleteDialog from 'Components/HistoryRowControls/DeleteDialog';
import { INeuroGrant } from 'neuro-data-structures';
import { mergeAll } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'Store/index';
import colors from '../../../../../../config/theme/colors';
import { formatPartialDate } from 'neuro-utils';
import ToolTip from 'Components/ToolTip';

export const StatusIcons = {
  inUse: <Icon icon="play" round color={'appBlueMedium'} />,
  toStart: <Icon icon="next" round color={'appBlueMedium'} />,
  paused: <Icon icon="pause" round color={'warningDefault'} />,
  ended: <Icon icon="stop" round color={'grayMedium'} />,
};

export const StatusIconByStatus = (status: 'inUse' | 'toStart' | 'paused' | 'ended') => {
  return StatusIcons[status];
};

export const RegimenIcons = {
  default: <Icon icon="repeat" />,
  'single-dose': <Icon icon="pinDrop" />,
  static: <Icon icon="doubleArrowRight" />,
  other: <Icon icon="question" />,
  onDemand: <Icon icon="noteAdd" />,
  custom: <Icon icon="description" />,
};

export const RegimenIconByType = (type: 'default' | 'single-dose' | 'onDemand' | 'custom' | 'static' | 'other') => {
  return (
    <ToolTip hover title={<FormattedMessage id={'medication.regimenTypes.' + type} />} content={RegimenIcons[type]} />
  );
};

export const SourceIcons: (
  medicationOrgs: INeuroGrant[],
) => Record<'stellarq' | 'integration' | string, JSX.Element> = (medicationOrgs) => ({
  stellarq: <Icon icon="sqicon" />,
  integration: <Icon icon="mergeType" />,
  ...mergeAll(medicationOrgs.map((org, i) => ({ [org.grantorOrg]: <Icon icon="lock" color={'color' + i} /> }))),
});

export const SourceIconBySource = (source: 'stellarq' | 'integration' | string, medicationOrgs: INeuroGrant[]) => {
  return SourceIcons(medicationOrgs)[source];
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -2,
    top: 6,
    color: 'white',
    backgroundColor: theme.palette.warning.dark,
    minWidth: '1.5rem',
    height: '1.5rem',
    padding: '0 0 0.1rem 0',
  },
}));

export const AdverseEventIcon = (eventsAmount?: number, round?: boolean) => {
  const IconElement = <Icon icon="bell" color={'warningDefault'} round={round} />;
  return eventsAmount && eventsAmount > 0 ? (
    <div>
      <StyledBadge badgeContent={eventsAmount}>{IconElement}</StyledBadge>
    </div>
  ) : (
    IconElement
  );
};

export const AlertIcon = (alerts?: { alertText?: JSX.Element }[], round?: boolean) => {
  const toolTips = alerts?.map((a) => a.alertText).filter((f) => f) || [];
  const IconElement = <Icon icon="alert" color={'warningDefault'} round={round} />;
  return toolTips.length > 0 ? (
    <ToolTip
      hover
      title={
        <div>
          <ul>
            {toolTips.map((t, i) => (
              <li key={i}>{t}</li>
            ))}
          </ul>
        </div>
      }
      content={IconElement}
    />
  ) : (
    IconElement
  );
};

export const HistoryEventListTopHeader = styled(Container)(({ theme }) => ({
  height: '4rem',
  borderBottom: `0.1rem solid ${theme.palette.grey[500]}`,
}));

export const HistoryEventListItemStyled = styled(Container)(({ theme }) => ({
  paddingLeft: '1rem',
  marginTop: '0.5rem',
  '* > &:first-of-type': { marginTop: '0.7rem' }, // Add a little margin if theres no top header
  '* > &:not(:last-of-type)': {
    paddingBottom: '0.5rem',
    borderBottom: `0.1rem solid ${theme.palette.grey[500]}`,
  },
}));

export const HistoryEventListTitle = styled(Item)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

export const HistoryEventListItem = ({
  title,
  value,
  fullWidthValue,
}: {
  title?: string | JSX.Element;
  value?: string | JSX.Element | null;
  fullWidthValue?: boolean;
}) => (
  <HistoryEventListItemStyled>
    {title && (
      <HistoryEventListTitle xs={4}>
        {typeof title === 'string' ? <FormattedMessage id={title} /> : title}
      </HistoryEventListTitle>
    )}
    <Item style={{ whiteSpace: 'pre-line' }} xs={fullWidthValue ? 12 : true}>
      {value ?? '-'}
    </Item>
  </HistoryEventListItemStyled>
);

export const DeleteButton = ({ text, document, buttonProps }: IDeleteButton) => {
  const userOrg = useAppSelector((s) => s.session.data?.orgid);
  const deletionDisabled = document._ownerOrg !== userOrg;

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  return (
    <>
      <ActionButtonRounded
        text={text ?? 'general.delete'}
        disabled={deletionDisabled}
        disabledTooltip={<FormattedMessage id="general.documentLocked" />}
        onClick={() => setDeleteDialogOpen(true)}
        width={15}
        height={3}
        fontSize={15}
        colorScheme="error"
        uppercase={false}
        {...buttonProps}
      />
      {deleteDialogOpen && (
        <DeleteDialog document={document} open={deleteDialogOpen} setOpen={() => setDeleteDialogOpen(false)} />
      )}
    </>
  );
};

interface IDeleteButton {
  text?: string;
  document: IControlProps;
  buttonProps?: Partial<IActionButtonRounded>;
}

export const EditButton = ({ text, onClick, startEdit, documentType, document, buttonProps }: INewOrEditButton) => {
  return documentType ? (
    <DocumentCreationButton
      text={text ?? 'general.edit'}
      name={documentType}
      document={document}
      width={11}
      height={3}
      fontSize={15}
      uppercase={false}
      newStyle
      onClick={onClick ?? startEdit?.({ _id: document?._id }, documentType)}
      {...buttonProps}
    />
  ) : (
    <ActionButtonRounded
      text={text ?? 'general.edit'}
      onClick={onClick}
      width={15}
      height={3}
      fontSize={15}
      uppercase={false}
      {...buttonProps}
    />
  );
};

interface INewOrEditButton {
  text?: string;
  onClick?: (e: React.MouseEvent) => void;
  startEdit?: (document: { _id?: string }, name?: string) => (e: React.MouseEvent) => void;
  documentType?: string;
  document?: IControlProps;
  buttonProps?: Partial<IActionButtonRounded>;
}

export const NewButton = ({ text, onClick }: INewOrEditButton) => {
  return (
    <button
      style={{
        border: 'none',
        backgroundColor: 'white',
        fontSize: '1.5rem',
        color: colors.appBlue.default,
        fontWeight: 600,
        fontFamily: 'inherit',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Container justifyContent={'center'} alignItems={'center'}>
        <Item style={{ marginRight: '0.5rem' }}>
          <Icon icon="plus" round />
        </Item>
        <Item>
          <FormattedMessage id={text} />
        </Item>
      </Container>
    </button>
  );
};

interface INewOrEditButton {
  text?: string;
  onClick?: (e: React.MouseEvent) => void;
  buttonProps?: Partial<IActionButtonRounded>;
}

const StyledHistoryListHeader = styled('div')(({ theme }) => ({
  fontWeight: 600,
  marginTop: '0.5rem',
  cursor: 'pointer',
  '* > &:not(:last-of-type)': {
    paddingBottom: '0.5rem',
    borderBottom: `0.1rem solid ${theme.palette.grey[300]}`,
  },
}));

export const HistoryListHeader = ({ open = false, date, type, typeIcon, onClick, dates }: IHistoryListHeader) => {
  const OpenIcon = open ? <Icon icon="downArrow" size={3.5} /> : <Icon icon="rightArrow" size={3.5} />;
  return (
    <StyledHistoryListHeader onClick={onClick}>
      <Container alignItems={'center'}>
        <Item xs={0.6}>
          <div style={{ marginTop: '-0.2rem' }}>{OpenIcon}</div>
        </Item>
        <Item xs={1.5}>{date && formatPartialDate(date)}</Item>
        <Item xs={0.7}>{typeIcon}</Item>
        <Item xs={true}>
          {type === 'regimen' ? (
            <FormattedMessage id="medication.regimenDosage" />
          ) : type === 'administration' ? (
            <FormattedMessage id="medication.administration.header" />
          ) : type === 'pause' ? (
            <FormattedMessage id="medication.pause" />
          ) : type === 'adverseEffect' ? (
            <FormattedMessage id="medication.adverseEffect" />
          ) : type === 'ending' ? (
            <FormattedMessage id="medication.ended" />
          ) : null}
          {dates && (
            <span style={{ marginLeft: '1rem', fontWeight: 400, color: colors.gray }}>
              {`(`}
              {dates.startDate && formatPartialDate(dates.startDate)}
              {` – `}
              {dates.endDate && formatPartialDate(dates.endDate)}
              {`)`}
            </span>
          )}
        </Item>
      </Container>
    </StyledHistoryListHeader>
  );
};

interface IHistoryListHeader {
  open: boolean;
  date?: PartialDate;
  type: 'regimen' | 'administration' | 'pause' | 'adverseEffect' | 'ending';
  typeIcon?: JSX.Element;
  onClick: () => void;
  dates?: { startDate?: PartialDate; endDate?: PartialDate };
}
