import { isEmpty } from 'ramda';
import { isTime } from 'neuro-utils';
import { isParkinsonInfusionType } from '../Document/Form/Medication/RegimenDialog/utils/isParkinsonInfusionType';
import { getLEDDClass } from 'neuro-calculation-commons';

/**
 * Check if infusion medication documents from integration has unfilled regimen doses or dose times
 * @param {IControlProps} docs - Documents to check
 * @returns {boolean} Whether documents have some unfilled dosage fields
 */
export const parkinsonInfusionMedicationUnfilledDose = (docs?: IControlProps[]): boolean => {
  const medIntegrationDocs = (docs || []).filter(
    (d) => d._type === 'medication' && d._lockedFor && getLEDDClass(d),
  ) as IMedication[];

  if (medIntegrationDocs.length < 1) return false;

  const medIntInfusionDocs = medIntegrationDocs.filter(
    (m) => m.regimen && m.regimen.some((r) => r.regimenType === 'custom'),
  );
  if (medIntInfusionDocs.length < 1) return false;

  const unfilledDataDoc = medIntInfusionDocs.find(
    (m) =>
      m.regimen &&
      m.regimen.find(
        (r) =>
          r.regimenType === 'custom' &&
          (!r.infusionDoses ||
            (r.infusionDoses &&
              ((!r.infusionDoses.morningDose && isParkinsonInfusionType(m) === 'gel') ||
                r.infusionDoses.upkeepDoses?.some(
                  (up) => isEmpty(up) || !up.upkeepDose || !isTime(up.upkeepDoseFrom) || !isTime(up.upkeepDoseTo),
                )))),
      ),
  );
  if (unfilledDataDoc) return true;
  return false;
};
