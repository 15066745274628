import { fetchWithOptions } from 'Utility/fetch';
import { getJWTData, parseJWTFromCookie } from 'Utility/jwtAuthTools';
import { makeLog } from 'Utility/logger';
import { mapSessionDataToDevices, mapSessionDataToSettings, mapSessionDataToTreatmentResponses } from './util';
import { fetchAvxPatientLink } from 'Routes/Treatment/Document/Form/PAPTherapy/functions';
import { TSessionData } from 'Routes/Treatment/Document/Form/PAPTherapy/interfaces';
import { IReadonlyDocument } from './readonlyGlobals';

/**
 * Fetch patients device session data from AVX and store session data to neuro, also updates patient link lastSync state
 */
export const syncAvxSessionData = async (): Promise<boolean> => {
  const orgid = getJWTData()?.orgid || null;
  const patientid = getJWTData()?.patientid || null;

  return await fetchWithOptions(
    `/api/resmed/v1/sync/${orgid}/${patientid}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'POST' },
  )
    .then((res) => {
      if (res.status === 200) {
        return true;
      } else throw res;
    })
    .catch((err: Error) => {
      makeLog('Error', { name: 'AVX session data sync', message: 'Response code not 200' }, err);
      return false;
    });
};

/**
 * Fetch patients device session data from neuro
 */
export const fetchAvxSessionData = async (): Promise<Array<TSessionData> | null> => {
  const orgid = getJWTData()?.orgid || null;
  const patientid = getJWTData()?.patientid || null;

  return await fetchWithOptions(
    `/api/resmed/v1/sessions/${orgid}/${patientid}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'GET' },
  )
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else throw res;
    })
    .catch((err: Error) => {
      makeLog('Error', { name: 'AVX session data fetch', message: 'Response code not 200' }, err);
      return null;
    });
};

/**
 * Combined function that syncs, fetches and maps/converts AVX session data
 */
export const getAvxSessionData = async (): Promise<Array<IReadonlyDocument>> => {
  // First check for AVX link, no need to proceed if none
  return fetchAvxPatientLink()
    .then((res0) => {
      if (!Array.isArray(res0) || res0.length === 0) return [];

      // If link exists, do sync and fetch of session data
      return syncAvxSessionData()
        .then((res1) => {
          if (!res1) return [];

          return fetchAvxSessionData().catch(() => []);
        })
        .catch(() => []);
    })
    .then((res) => {
      // Map session data to device/setting/response data used by UI
      if (Array.isArray(res) && res.length > 0) {
        return [
          ...mapSessionDataToDevices(res),
          ...mapSessionDataToSettings(res),
          ...mapSessionDataToTreatmentResponses(res),
        ];
      } else return [];
    })
    .catch(() => []);
};
