import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';

import colors from '../../config/theme/colors';
import CircularProgressLocal from '../CircularProgress';
import { Container, Item } from '../Grid';
import ToolTip from '../ToolTip';

interface IStyleProps {
  width?: number;
  height?: number;
  border?: boolean;
  fontSize?: number;
  fontWeight?: number;
  colorscheme: string;
  alternate?: string;
  disabled?: boolean;
  filled?: boolean;
}

const colorSchemes: { [key: string]: { [key: string]: string } } = {
  default: {
    text: colors.primary,
    border: colors.primary,
    backgroundHover: colors.primary + '1A',
  },
  cancel: {
    text: colors.primary,
    border: colors.appBlue.lightest,
    backgroundHover: colors.primary + '1A',
    backgroundHoverFilled: colors.primary + 'D9',
  },
  error: {
    text: colors.error.default,
    border: colors.error.lightest,
    backgroundHover: 'rgba(186, 60, 60, 0.1)',
  },
  warning: {
    text: colors.warning.dark,
    border: colors.warning.dark,
    backgroundHover: colors.warning.light,
  },
};

const shouldNotPassTheseProps = (prop: string | number | symbol) =>
  prop !== 'height' &&
  prop !== 'width' &&
  prop !== 'border' &&
  prop !== 'fontSize' &&
  prop !== 'fontWeight' &&
  prop !== 'colorscheme' &&
  prop !== 'alternate' &&
  prop !== 'filled' &&
  prop !== 'disabled'; // Especially important. Buttons wont work properly with tooltips if disabled;

const StyledButton = styled('button', { shouldForwardProp: shouldNotPassTheseProps })((props: IStyleProps) => ({
  height: `${props.height || '5'}rem`,
  width: `${props.width || '25'}rem`,
  border: !props.border
    ? 'none'
    : props.alternate
      ? `1px solid ${colors.white}`
      : `1px solid ${colorSchemes[props.colorscheme].border}`,
  cursor: props.disabled === true ? 'default' : 'pointer',
  lineHeight: 1,
  backgroundColor: props.filled ? colorSchemes[props.colorscheme].text : 'inherit',
  '&:hover': {
    backgroundColor: props.filled
      ? colorSchemes[props.colorscheme].backgroundHoverFilled
      : props.alternate
        ? 'rgba(255, 255, 255, 0.1)'
        : colorSchemes[props.colorscheme].backgroundHover,
  },
  padding: '0 1rem',
  opacity: props.disabled === true ? '0.4' : '1',
  boxSizing: 'content-box' as const,
  fontFamily: 'inherit',
}));

const StyledRowItem = styled(Item, { shouldForwardProp: shouldNotPassTheseProps })((props: IStyleProps) => ({
  fontSize: `${props.fontSize || '24'}px`,
  fontWeight: `${props.fontWeight || 600}`,
  color: props.filled
    ? colors.white
    : props.alternate
      ? colors.white
      : props.colorscheme
        ? colorSchemes[props.colorscheme].text
        : colors.primary,
  textAlign: 'center' as const,
  textTransform: 'uppercase' as const,
  userSelect: 'none' as const,
}));

const attachTooltip = (element: JSX.Element, tip?: JSX.Element): JSX.Element => (
  <ToolTip title={tip} content={element} hover={true} />
);

const ButtonElement = ({
  text,
  onClick,
  hover,
  width,
  height,
  border = true,
  fontSize,
  fontWeight = 600,
  disabled,
  colorScheme = 'default',
  alternate,
  loading = false,
  filled = false,
}: IActionButton): JSX.Element => (
  <StyledButton
    width={width}
    height={height}
    border={border}
    colorscheme={colorScheme}
    alternate={alternate ? 'true' : undefined}
    onClick={onClick && !disabled && !loading ? onClick : undefined}
    disabled={disabled && disabled === true ? true : false}
    onMouseEnter={(): void => {
      hover ? hover(true) : undefined;
    }}
    onMouseLeave={(): void => {
      hover ? hover(false) : undefined;
    }}
    filled={filled}
  >
    <Container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
      {loading ? (
        <Item>
          <CircularProgressLocal
            size={(height || 6) / 2 + 'rem'}
            style={{ display: 'block', color: alternate ? colors.white : colors.primary }}
          />
        </Item>
      ) : (
        <StyledRowItem
          xs={12}
          fontSize={fontSize}
          fontWeight={fontWeight}
          colorscheme={colorScheme}
          alternate={alternate ? 'true' : undefined}
          filled={filled}
        >
          {typeof text === 'string' ? <FormattedMessage id={text} /> : text}
        </StyledRowItem>
      )}
    </Container>
  </StyledButton>
);

const ActionButton = ({ disabled, disabledTooltip, tooltip, ...other }: IActionButton): JSX.Element => (
  // Need this flex to align tooltips properly
  <div style={{ display: 'flex' }} id={'actionButton'}>
    {(disabled && disabledTooltip) || tooltip ? (
      attachTooltip(
        <div>
          <ButtonElement disabled={disabled} {...other} />
        </div>,
        disabled ? disabledTooltip : tooltip,
      )
    ) : (
      <ButtonElement disabled={disabled} {...other} />
    )}
  </div>
);

export interface IActionButton {
  text: string | JSX.Element;
  onClick?: (e: React.MouseEvent) => void;
  hover?: (trueOrFalse: boolean) => void;
  width?: number;
  height?: number;
  fontSize?: number;
  fontWeight?: 400 | 600;
  border?: boolean;
  /** If button is disabled */
  disabled?: boolean;
  /** Tooltip to be shown when hovering and button is disabled */
  disabledTooltip?: JSX.Element;
  /** Tooltip to be shown always when hovering the button */
  tooltip?: JSX.Element;
  colorScheme?: 'default' | 'cancel' | 'error' | 'warning';
  alternate?: boolean;
  loading?: boolean;
  filled?: boolean;
}

export default ActionButton;
