import { createReducer } from '@reduxjs/toolkit';
import { INeuroCommit, INeuroDocument } from 'neuro-data-structures';
import { evolve, map, mergeRight } from 'ramda';
import { mergeDocument } from 'Utility/documentHandling';
import { reduxActions } from './actions';

const initialState: IDocumentStore = {
  isLoading: undefined,
  isCreating: undefined,
  documents: undefined,
  sortedAndMergedDocuments: undefined,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(reduxActions.loadAction, () => {
      return { isLoading: true, documents: [] };
    })
    .addCase(reduxActions.receiveAction, (state, action) => {
      return mergeRight(state, {
        isLoading: false,
        documents: action.payload?.documents || [],
        sortedAndMergedDocuments: action.payload?.sortedAndMergedDocuments || [],
      });
    })
    .addCase(reduxActions.clearAction, () => {
      return initialState;
    })
    .addCase(reduxActions.newDocumentAction, (state, action) => {
      const { data } = action.payload || { data: {} };
      const documents = state.documents ? [...state.documents] : [];
      const sortedAndMergedDocuments = state.sortedAndMergedDocuments ? [...state.sortedAndMergedDocuments] : [];

      documents.unshift(data as INeuroDocument);
      sortedAndMergedDocuments.unshift(mergeDocument(data as INeuroDocument));
      return mergeRight(state, { documents: documents, sortedAndMergedDocuments: sortedAndMergedDocuments });
    })
    .addCase(reduxActions.deleteDocumentAction, (state, action) => {
      const documents = state.documents ? [...state.documents] : [];
      const sortedAndMergedDocuments = state.sortedAndMergedDocuments ? [...state.sortedAndMergedDocuments] : [];

      return mergeRight(state, {
        documents: documents.filter((d) => d.documentId !== action.payload),
        sortedAndMergedDocuments: sortedAndMergedDocuments.filter((sd) => sd._id !== action.payload),
      });
    })
    .addCase(reduxActions.newCommitAction, (state, action) => {
      const { id, data } = action.payload || { id: '', data: {} };
      const documents = state.documents ? [...state.documents] : [];
      const sortedAndMergedDocuments = state.sortedAndMergedDocuments ? [...state.sortedAndMergedDocuments] : [];

      // Add the new commit to the right document
      documents.forEach((d: INeuroDocument) => d.documentId === id && data && d.commits.unshift(data as INeuroCommit));

      const newMergedDocument = mergeDocument(documents.find((d) => d.documentId === id));

      state.documents = documents;
      state.sortedAndMergedDocuments = sortedAndMergedDocuments.map((d) => (d._id === id ? newMergedDocument : d));
    })
    .addCase(reduxActions.updateCommitAction, (state, action) => {
      const { id, cid, close, data } = action.payload || { id: '', cid: '', close: false, data: {} };
      const documents = state.documents ? [...state.documents] : [];
      const sortedAndMergedDocuments = state.sortedAndMergedDocuments ? [...state.sortedAndMergedDocuments] : [];

      const updatedDocuments = documents.map((d: INeuroDocument) => {
        if (d.documentId === id) {
          const updatedDocument = evolve(
            {
              commits: map((c: INeuroCommit) => {
                return c.commitId === cid
                  ? mergeRight(c, {
                      data,
                      // When closing the commit, set commitDate
                      ...(close ? { commitDate: Date.now() } : {}),
                    })
                  : c;
              }),
            },
            d,
          );

          const updateIndex = sortedAndMergedDocuments.findIndex((d) => d._id === id);
          sortedAndMergedDocuments[updateIndex] = mergeDocument(updatedDocument);

          return updatedDocument;
        } else return d;
      });

      return mergeRight(state, { documents: updatedDocuments, sortedAndMergedDocuments: sortedAndMergedDocuments });
    })
    .addCase(reduxActions.updateDocumentAction, (state, action) => {
      let documents = state.documents ? [...state.documents] : [];

      // Update old or insert new document
      if (documents.find((d) => d.documentId === action.payload.documentId)) {
        documents = (state.documents || []).map((d: INeuroDocument) =>
          d.documentId === action.payload.documentId ? action.payload : d,
        );
      } else {
        documents.unshift(action.payload);
      }

      let sortedAndMergedDocuments = state.sortedAndMergedDocuments ? [...state.sortedAndMergedDocuments] : [];
      const newMergedDocument = mergeDocument(action.payload);

      if (sortedAndMergedDocuments.find((d) => d._id === action.payload.documentId)) {
        sortedAndMergedDocuments = sortedAndMergedDocuments.map((d) =>
          d._id === action.payload.documentId ? newMergedDocument : d,
        );
      } else {
        sortedAndMergedDocuments.unshift(newMergedDocument);
      }

      state.documents = documents;
      state.sortedAndMergedDocuments = sortedAndMergedDocuments;
    })
    .addCase(reduxActions.cancelCommitAction, (state, action) => {
      const { id, cid } = action.payload || { id: '', cid: '' };
      const documents = state.documents ? [...state.documents] : [];
      const sortedAndMergedDocuments = state.sortedAndMergedDocuments ? [...state.sortedAndMergedDocuments] : [];

      const updatedDocuments = documents.map((d: INeuroDocument) =>
        d.documentId === id ? mergeRight(d, { commits: d.commits.filter((c) => c.commitId !== cid) }) : d,
      );

      const updatedDocument = updatedDocuments.find((d) => d.documentId === id);
      const newMergedDocument = updatedDocument && mergeDocument(updatedDocument);

      return mergeRight(state, {
        documents: updatedDocuments,
        sortedAndMergedDocuments: sortedAndMergedDocuments.map((d) => (d._id === id ? newMergedDocument : d)),
      });
    })
    .addCase(reduxActions.modifyFieldAction, (state, action) => {
      const { id, commit } = action.payload || { id: undefined, commit: undefined };
      const documents = state.documents ? [...state.documents] : [];
      const sortedAndMergedDocuments = state.sortedAndMergedDocuments ? [...state.sortedAndMergedDocuments] : [];

      const updatedDocuments = documents.map((d: INeuroDocument) => {
        if (d.documentId === id && commit) {
          const updatedDocument = mergeRight(d, { commits: [...d.commits, commit] });

          const updateIndex = sortedAndMergedDocuments.findIndex((d) => d._id === id);
          sortedAndMergedDocuments[updateIndex] = mergeDocument(updatedDocument);

          return updatedDocument;
        } else return d;
      });

      return mergeRight(state, { documents: updatedDocuments, sortedAndMergedDocuments: sortedAndMergedDocuments });
    })
    .addCase(reduxActions.modifyLockedFieldAction, (state, action) => {
      const { id, commit } = action.payload || { id: undefined, commit: undefined };
      const documents = state.documents ? [...state.documents] : [];
      const sortedAndMergedDocuments = state.sortedAndMergedDocuments ? [...state.sortedAndMergedDocuments] : [];

      const updatedDocuments = documents.map((d: INeuroDocument) => {
        if (d.documentId === id && commit) {
          const updatedDocument = mergeRight(d, { commits: [...d.commits, commit] });

          const updateIndex = sortedAndMergedDocuments.findIndex((d) => d._id === id);
          sortedAndMergedDocuments[updateIndex] = mergeDocument(updatedDocument);

          return updatedDocument;
        } else return d;
      });

      return mergeRight(state, { documents: updatedDocuments, sortedAndMergedDocuments: sortedAndMergedDocuments });
    })
    .addCase(reduxActions.medicationEndReasonAction, (state, action) => {
      const { id, commit } = action.payload || { id: undefined, commit: undefined };
      const documents = state.documents ? [...state.documents] : [];
      const sortedAndMergedDocuments = state.sortedAndMergedDocuments ? [...state.sortedAndMergedDocuments] : [];

      const updatedDocuments = documents.map((d: INeuroDocument) => {
        if (d.documentId === id && commit) {
          const updatedDocument = mergeRight(d, { commits: [...d.commits, commit] });

          const updateIndex = sortedAndMergedDocuments.findIndex((d) => d._id === id);
          sortedAndMergedDocuments[updateIndex] = mergeDocument(updatedDocument);

          return updatedDocument;
        } else return d;
      });

      return mergeRight(state, { documents: updatedDocuments, sortedAndMergedDocuments: sortedAndMergedDocuments });
    })
    .addCase(reduxActions.setNewDocAction, (state, action) => {
      const { name, id } = action.payload || {};
      if (name) state.isCreating = { name, id };
    })
    .addCase(reduxActions.unsetNewDocAction, (state) => {
      state.isCreating = undefined;
    })
    .addDefaultCase((state) => {
      return state;
    });
});

export default reducer;
