/**
 * Test which type of parkinson custom regimen medication is, if any
 * @param {IMedication} document - Medication document
 * @returns {'infusion' | 'gel' | false} If it is or isnt
 */

export const isParkinsonInfusionType = (document: IMedication): 'infusion' | 'gel' | false => {
  const infusionTypes = [{ substance: /apomorfiini|foslevodopa/, dosageForm: /inf.*?neste/ }] as Array<{
    substance: RegExp;
    dosageForm: RegExp;
  }>;
  const gelTypes = [{ substance: /levodopa/, dosageForm: /ge+l/ }] as Array<{
    substance: RegExp;
    dosageForm: RegExp;
  }>;

  const { medicationSubstances, dosageForm } = document;
  if (!medicationSubstances || !dosageForm) return false;

  const matchInfusion = infusionTypes.find(
    (t) => t.substance.test(medicationSubstances) && t.dosageForm.test(dosageForm),
  );
  if (matchInfusion) return 'infusion';

  const matchGel = gelTypes.find((t) => t.substance.test(medicationSubstances) && t.dosageForm.test(dosageForm));
  if (matchGel) return 'gel';
  return false;
};
