import { createReducer } from '@reduxjs/toolkit';
import { reduxActions } from './actions';
import { IReadonlyData } from './readonlyGlobals';

const initialState: IReadonlyData = {
  documents: [],
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(reduxActions.loadDataAction, (state, action) => {
      return {
        ...state,
        documents: action.payload.documents,
      };
    })
    .addCase(reduxActions.clearDataAction, () => {
      return initialState;
    })
    .addDefaultCase((state) => {
      return state;
    });
});

export default reducer;
