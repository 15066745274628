import { IAddon, IData, IDataPoint, IEvent, IItem } from 'Components/sq-graphics/interfaces';
import { exists, isPartialDate } from 'neuro-utils';
import { includes } from 'ramda';
import { getSPMSLorscheiderDate, getSPMSModifiedExpandDate } from 'neuro-calculation-commons';
import { INeuroDocument } from 'neuro-data-structures';

export const convertMsTypesToTimeline = (
  docs: IDiagnosis[],
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IAddon> | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  docs.forEach((doc) => {
    if (!isPartialDate(doc.date) || doc.diagnosis !== 'G35' || !doc.typeOfDisease) return undefined;
    const events: IEvent[] = [];

    Array.isArray(doc.typeOfDisease) &&
      doc.typeOfDisease.forEach((t: IMSType) => {
        t.date &&
          events.push({
            date: dateFromPartialUpdateTimeframe(t.date),
            eventType: 'text',
            title: `G35 (${includes(t.type, ['SP', 'PP', 'RR']) ? t.type : 'NA'})`,
            description: {
              value: t.type ? fm(`diagnosis.msTitle.g35.${t.type}`) : undefined,
              condition: exists(t.type),
            },
          });
      });
    addons.push({
      id: 'diagnosis',
      title: '',
      titleDescription: undefined,
      events: events,
      items: [],
    });

    return;
  });
  return addons.length > 0 ? addons : undefined;
};

export const convertSpmsToTimeline = (
  docs: IDiagnosis[],
  allDocs: INeuroDocument[],
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IAddon> | undefined => {
  if (docs.length === 0) return undefined;
  const addons: IAddon[] = [];
  const lorscheider = getSPMSLorscheiderDate(allDocs) as PartialDate | null;
  const modifiedExpand = getSPMSModifiedExpandDate(allDocs) as PartialDate | null;
  const events: IEvent[] = [];
  if (lorscheider) {
    events.push({
      date: dateFromPartialUpdateTimeframe(lorscheider),
      eventType: 'criteria',
      title: fm('diagnosis.msType.possibleChangeOfMsType'),
      description: fm('diagnosis.msType.lorscheider'),
    });
  }
  if (modifiedExpand) {
    events.push({
      date: dateFromPartialUpdateTimeframe(modifiedExpand),
      eventType: 'criteria',
      title: fm('diagnosis.msType.possibleChangeOfMsType'),
      description: fm('diagnosis.msType.modifiedExpand'),
    });
  }
  if (events.length > 0) {
    addons.push({
      id: 'diagnosis',
      title: fm('diagnosis.title'),
      titleDescription: undefined,
      items: [],
      events: events,
    });
  }
  return addons.length > 0 ? addons : undefined;
};

export const convertRelapseAndEarlySymptomToTimeline = (
  docs: Array<IRelapse | IMsSymptoms>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IAddon[] | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  docs.forEach((doc) => {
    const events: IEvent[] = [];

    if (doc._type === 'relapse') {
      const relapseDoc = doc as IRelapse;
      if (!relapseDoc) return;
      const cortisoneOrPlasma = relapseDoc.wasCortisoneGiven === 'yes' || relapseDoc.wasPlasmaGiven === 'yes';
      if (!isPartialDate(relapseDoc.startDate)) return;
      events.push({
        date: dateFromPartialUpdateTimeframe(relapseDoc.startDate),
        eventType: 'symptom',
        title: fm(cortisoneOrPlasma ? 'relapse.tertiaryTitle' : 'relapse.secondaryTitle'),
        description: [
          {
            title: `${fm('relapse.symptoms.title')}`,
            values: (relapseDoc.symptoms ?? []).map((s) => `${fm(`relapse.symptoms.opts.${s}`)}\n`).join(''),
            condition: Array.isArray(relapseDoc.symptoms) && relapseDoc.symptoms.length > 0,
          },
          {
            title: `${fm('relapse.wasCortisoneGiven')}`,
            values: fm(`general.${relapseDoc.wasCortisoneGiven}`),
            condition: exists(relapseDoc.wasCortisoneGiven),
          },
          {
            title: `${fm('relapse.wasPlasmaGiven')}`,
            values: fm(`general.${relapseDoc.wasPlasmaGiven}`),
            condition: exists(relapseDoc.wasPlasmaGiven),
          },
        ],
        priority: cortisoneOrPlasma ? 'high' : 'low',
      });
    }
    if (doc._type === 'msSymptoms') {
      const msSymptomDoc = doc as IMsSymptoms;
      if (!msSymptomDoc) return;
      if (!isPartialDate(msSymptomDoc.symptomOnsetDate)) return;
      const cortisoneOrPlasma = msSymptomDoc.msWasCortisoneGiven === 'yes' || msSymptomDoc.msWasPlasmaGiven === 'yes';
      events.push({
        date: dateFromPartialUpdateTimeframe(msSymptomDoc.symptomOnsetDate),
        eventType: 'symptom',
        title: fm('diagnosis.msEarlySymption'),
        description: [
          {
            title: `${fm('diagnosis.msSymptom.title')}`,
            values: (msSymptomDoc.msSymptoms ?? []).map((s) => `${fm(`diagnosis.msSymptom.opts.${s}`)}\n`).join(''),
            condition: Array.isArray(msSymptomDoc.msSymptoms) && msSymptomDoc.msSymptoms.length > 0,
          },
          {
            title: `${fm('diagnosis.msWasCortisoneGiven')}`,
            values: fm(`general.${msSymptomDoc.msWasCortisoneGiven}`),
            condition: exists(msSymptomDoc.msWasCortisoneGiven),
          },
          {
            title: `${fm('diagnosis.msWasPlasmaGiven')}`,
            values: fm(`general.${msSymptomDoc.msWasPlasmaGiven}`),
            condition: exists(msSymptomDoc.msWasPlasmaGiven),
          },
        ],
        priority: cortisoneOrPlasma ? 'high' : 'low',
      });
    }
    addons.push({
      id: 'relapse',
      title: fm('graph.relapseOrEarlySymptom'),
      titleDescription: undefined,
      items: [],
      events: events,
    });
  });
  return addons.length > 0 ? addons : undefined;
};

export const convertEdssToGraph = (
  docs: Array<INeurologicalStatusAndEDSS>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!isPartialDate(d.date) || !exists(d.edssStep)) return;
    d.date &&
      (d.edssStep || d.edssStep === 0) &&
      dataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: d.edssStep,
        title: d.edssStep.toString(),
        id: 'edss',
        description: fm(
          `neurologicalStatusAndEDSS.edssStep.opts.${
            d.edssStep === 0 || d.edssStep === 10 ? d.edssStep : d.edssStep?.toFixed(1)
          }`,
        ),
      });
  });
  return [
    {
      dataPoints: dataPoints,
      id: 'edss',
      legend: 'EDSS',
      type: 'lineGraph',
    },
  ];
};

export const convertMriBrainToGraph = (
  docs: Array<IMRI>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPointsTotal: IDataPoint[] = [];
  const dataPointsInfra: IDataPoint[] = [];
  const dataPointsSupra: IDataPoint[] = [];
  const dataPointsBoost: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    typeof d.brainT2Flair?.total?.lesionsDelta === 'number' &&
      d.date &&
      dataPointsTotal.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: d.brainT2Flair.total.lesionsDelta,
        id: 'total',
        title: `${d.brainT2Flair.total.lesionsDelta}`,
      });
    typeof d.brainT2Flair?.infratentorial?.lesionsDelta === 'number' &&
      d.date &&
      dataPointsInfra.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: d.brainT2Flair.infratentorial.lesionsDelta,
        id: 'infra',
        title: `${d.brainT2Flair.infratentorial.lesionsDelta}`,
      });
    typeof d.brainT2Flair?.supratentorial?.lesionsDelta === 'number' &&
      d.date &&
      dataPointsSupra.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: d.brainT2Flair.supratentorial.lesionsDelta,
        id: 'supra',
        title: `${d.brainT2Flair.supratentorial.lesionsDelta}`,
      });
    typeof d.brainT1?.gadoliniumEnhanced?.lesionsDelta === 'number' &&
      d.date &&
      dataPointsBoost.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: d.brainT1.gadoliniumEnhanced.lesionsDelta,
        id: 'enhanced',
        title: `${d.brainT1.gadoliniumEnhanced.lesionsDelta}`,
      });
  });
  return [
    {
      dataPoints: dataPointsTotal,
      id: 'total',
      legend: fm('imaging.brainT2FlairTotalShort') + ' Δ',
      type: 'lineGraph',
    },
    {
      dataPoints: dataPointsInfra,
      id: 'infra',
      legend: fm('imaging.brainT2FlairInfraShort') + ' Δ',
      type: 'lineGraph',
    },
    {
      dataPoints: dataPointsSupra,
      id: 'supra',
      legend: fm('imaging.brainT2FlairSupraShort') + ' Δ',
      type: 'lineGraph',
    },
    {
      dataPoints: dataPointsBoost,
      id: 'enhanced',
      legend: fm('imaging.brainT1GadoliniumShort') + ' Δ',
      type: 'lineGraph',
    },
  ];
};

export const convertMriSpinalToGraph = (
  docs: Array<IMRI>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPointsTotal: IDataPoint[] = [];
  const dataPointsGadoliniumEnhanced: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    typeof d.spinalCordT2?.total?.lesionsDelta === 'number' &&
      d.date &&
      dataPointsTotal.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: d.spinalCordT2.total.lesionsDelta,
        id: 'total',
        title: `${d.spinalCordT2.total.lesionsDelta}`,
      });
    typeof d.spinalCordT1?.gadoliniumEnhanced?.lesionsDelta === 'number' &&
      d.date &&
      dataPointsGadoliniumEnhanced.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: d.spinalCordT1.gadoliniumEnhanced.lesionsDelta,
        id: 'gadoliniumEnhanced',
        title: `${d.spinalCordT1.gadoliniumEnhanced.lesionsDelta}`,
      });
  });
  return [
    {
      dataPoints: dataPointsTotal,
      id: 'total',
      legend: fm('imaging.spinalCordT2TotalShort') + ' Δ',
      type: 'lineGraph',
    },
    {
      dataPoints: dataPointsGadoliniumEnhanced,
      id: 'gadoliniumEnhanced',
      legend: fm('imaging.spinalCordT1GadoliniumShort') + ' Δ',
      type: 'lineGraph',
    },
  ];
};

type IInflammatoryDiseaseActivityMonitoringDoc =
  | IInflammatoryDiseaseActivityStart
  | IInflammatoryDiseaseActivityStart2024
  | IInflammatoryDiseaseActivityMonitoring
  | IInflammatoryDiseaseActivityMonitoring2024;

export const convertInflammatoryDiseaseActivityToTimeline = (
  docs: Array<IInflammatoryDiseaseActivityMonitoringDoc>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IAddon[] | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  docs.forEach((doc) => {
    const events: IEvent[] = [];
    const inflaDoc = doc;
    if (!inflaDoc) return;
    if (!isPartialDate(inflaDoc.date)) return;

    const version = inflaDoc.version === '2024' ? '2024' : '2020';

    const inflammatoryDiseaseActivity = doc.inflammatoryDiseaseActivity;
    if (!inflammatoryDiseaseActivity || inflammatoryDiseaseActivity === 'unknown') return;
    events.push({
      date: dateFromPartialUpdateTimeframe(inflaDoc.date),
      eventType: 'activity',
      title:
        fm('inflammatoryDiseaseActivity.title') + `\n(${fm('inflammatoryDiseaseActivity.careGuidelines')} ${version})`,
      description: [
        {
          title: `${
            inflaDoc._type === 'inflammatoryDiseaseActivityStart'
              ? fm('inflammatoryDiseaseActivity.inflammatoryDiseaseActivityStart')
              : fm('inflammatoryDiseaseActivity.inflammatoryDiseaseActivityMonitoring')
          }`,
          values: fm('inflammatoryDiseaseActivity.opts.' + inflammatoryDiseaseActivity),
          condition: true,
        },
      ],
      priority:
        inflammatoryDiseaseActivity === 'veryActiveMS'
          ? 'high'
          : inflammatoryDiseaseActivity === 'activeMS'
            ? 'normal'
            : inflammatoryDiseaseActivity === 'stableMS'
              ? 'low'
              : undefined,
    });

    addons.push({
      id: 'diseaseActivity',
      title: '',
      titleDescription: undefined,
      items: [],
      events: events,
    });
  });
  return addons.length > 0 ? addons : undefined;
};

export const convertStemcellsToTimeline = (
  docs: Array<IProcedure>,
  fm: (id: string) => string,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
): Array<IAddon> | undefined => {
  if (docs.length === 0) return;
  const stemCellEvents: IEvent[] = [];
  const stemCellItems: IItem[] = [];

  docs.forEach((doc) => {
    if (!isPartialDate(doc.date)) return;
    if (!doc.code) return;

    doc.date &&
      stemCellEvents.push({
        date: dateFromPartialUpdateTimeFrame(doc.date),
        title: fm('medication.otherTreatment.stemCellTransplant'),
        eventType: 'start',
      });
    doc.date &&
      stemCellItems.push({
        start: dateFromPartialUpdateTimeFrame(doc.date),
        title: fm('medication.otherTreatment.stemCellTherapy'),
      });
  });
  return [
    {
      id: '1269349006',
      title: fm('medication.otherTreatment.stemCellTherapy'),
      events: stemCellEvents,
      items: stemCellItems,
    },
  ];
};
