import colors from '../../config/theme/colors';
import {
  AddonColors,
  IGraphDimensions,
  TExpansionPanelTheme,
  TGraphMenuTheme,
  TGraphSettings,
  TSingleAddonTheme,
  IHeights,
} from './interfaces';

const graphHeights: IHeights = {
  default: 30,
  ninmt: 30,
};

export const tightDimensions: IGraphDimensions = {
  paddingTop: 0.1,
  paddingBottom: 1.5,
  timeframeSelector: {
    selectors: {
      height: 3,
    },
    labels: {
      height: 2,
    },
  },
  leftColumn: {
    width: 28.5,
    paddingLeft: 4,
  },
  rightColumn: {
    width: 14,
    paddingRight: 2,
  },
  graphs: {
    marginTop: 1.25,
    marginBottom: 1.25,
    scaleArea: {
      width: 8,
    },
  },
  addons: {
    marginTop: 0.25,
    marginBottom: 0.25,
    divider: {
      width: 0.7,
    },
  },
  timeline: {
    height: 1.5,
    marginTop: 0.5,
    marginBottom: 0.5,
  },
  legends: {
    paddingTop: 0.75,
    paddingBottom: 0.75,
    marginTop: 0.25,
    marginBottom: 0.25,
  },
  graphHeights: graphHeights,
};

export const normalDimensions: IGraphDimensions = {
  paddingTop: 0.1,
  paddingBottom: 1.5,
  timeframeSelector: {
    selectors: {
      height: 3.5,
    },
    labels: {
      height: 2,
    },
  },
  leftColumn: {
    width: 28.5,
    paddingLeft: 4,
  },
  rightColumn: {
    width: 14,
    paddingRight: 2,
  },
  graphs: {
    marginTop: 1.75,
    marginBottom: 1.25,
    scaleArea: {
      width: 8,
    },
  },
  addons: {
    marginTop: 0.25,
    marginBottom: 0.25,
    divider: {
      width: 0.7,
    },
  },
  timeline: {
    height: 1.5,
    marginTop: 0.5,
    marginBottom: 0.5,
  },
  legends: {
    paddingTop: 0.75,
    paddingBottom: 0.75,
    marginTop: 0.25,
    marginBottom: 0.25,
  },
  graphHeights: graphHeights,
};

export const looseDimensions: IGraphDimensions = {
  paddingTop: 0.1,
  paddingBottom: 1.5,
  timeframeSelector: {
    selectors: {
      height: 3,
    },
    labels: {
      height: 2,
    },
  },
  leftColumn: {
    width: 28.5,
    paddingLeft: 4,
  },
  rightColumn: {
    width: 14,
    paddingRight: 2,
  },
  graphs: {
    marginTop: 1.25,
    marginBottom: 1.25,
    scaleArea: {
      width: 8,
    },
  },
  addons: {
    marginTop: 0.25,
    marginBottom: 0.25,
    divider: {
      width: 0.7,
    },
  },
  timeline: {
    height: 1.5,
    marginTop: 0.5,
    marginBottom: 0.5,
  },
  legends: {
    paddingTop: 0.75,
    paddingBottom: 0.75,
    marginTop: 0.25,
    marginBottom: 0.25,
  },
  graphHeights: graphHeights,
};

export const defaultSettings: Required<TGraphSettings> = {
  topDataAddons: [],
  graphMenusToBeHiddenIfEmpty: [],
  timeframeSelectorsHiddenByDefault: false,
  drawReferenceGraphsBelowEachOther: false,
  expansionPanelsHiddenByDefault: ['endedMedications'],
  defaultTimeframeLength: 'all',
  defaultTimeframe: null,
  reduxStateSelectedGraph: null,
  layout: 'normal',
  timeframeLengthOptions: ['3d', '7d', '14d', '1m', '2m', '3m', '6m', '9m', '1y', '2y', '3y', '5y', 'all'],
  hiddenAddons: [],
  hiddenGraphs: [],
  addonSettings: {
    patientSelfReport: {
      theme: 'stellarqMy',
    },
    relapse: {
      theme: 'defaultWithRedEvents',
    },
    diseaseActivity: {
      theme: 'defaultWithWarningEvents',
    },
    earlySymptoms: {
      theme: 'defaultWithWarningEvents',
    },
    rtms: {
      theme: 'defaultWithWarningEvents',
    },
    tdcs: {
      theme: 'defaultWithWarningEvents',
    },
    passTest: {
      theme: 'defaultWithWarningEvents',
    },
    intensiveCareHospitalization: {
      theme: 'flirt',
    },
    hospitalWardHospitalization: {
      theme: 'warningLight',
    },

    /**
     * MS medication
     */

    /** By name */
    avonex: {
      theme: 'mystic',
    },
    betaferon: {
      theme: 'dollarBill',
    },
    rebif: {
      theme: 'skyBlue',
    },
    extavia: {
      theme: 'aero',
    },
    plegridy: {
      theme: 'airForceBlue',
    },

    /** By ATC code */
    // extavia
    // For now, medication search does not find EXTAVIA. Fallback here to possibly refer to it by what should be its ATC code.
    L03AB08: {
      theme: 'aero',
    },
    // copaxone
    L03AX13: {
      theme: 'interdimensionalBlue1',
    },
    // gilenya
    L04AA27: {
      theme: 'vividOrange',
    },
    // tysabri
    L04AA23: {
      theme: 'darkRed',
    },
    // lemtrada
    L04AA34: {
      theme: 'red',
    },
    // tecfidera
    L04AX07: {
      theme: 'caputMortuum',
    },
    // aubagio
    L04AA31: {
      theme: 'bluePigment',
    },
    // zinbryta
    // https://www.fimea.fi/-/zinbryta-valmisteen-myyntiluvan-valitonta-keskeyttamista-ja-valmisteen-poisvetoa-suositellaan
    L04AC01: {
      theme: 'blueYonder',
    },
    // mavenclad
    L04AA40: {
      theme: 'egyptianBlue',
    },
    // mayzent
    L04AA42: {
      theme: 'blueBonnet',
    },
    // ocrevus
    L04AA36: {
      theme: 'forestGreen',
    },
    // mabthera
    L01XC02: {
      theme: 'bitterLemon',
    },
    // mabthera - new
    L01FA01: {
      theme: 'bitterLemon',
    },
    // mitoksantronihydrokloridi
    L01DB07: {
      theme: 'interdimensionalBlue2',
    },
    // atsatiopriini
    L04AX01: {
      theme: 'hotMagenta',
    },
    // ofatumufabi
    L01XC10: {
      theme: 'blueBonnet',
    },
    // ofatumumabi - new
    L04AA52: {
      theme: 'blueBonnet',
    },
    L01FA02: {
      theme: 'blueBonnet',
    },
    // ponesimodi
    L04AA50: {
      theme: 'darkCyan',
    },
    // vumerity
    L04AX09: {
      theme: 'uglyPurple',
    },
    // zeposia
    L04AA38: {
      theme: 'delightfulPeach',
    },

    /**
     * Other medication
     */

    // MG
    mgravisImmunological: {
      theme: 'darkCyan2',
    },
    mgravisSymptomatic: {
      theme: 'warningDefault',
    },
    // Tutkimuslääkkeet
    clinicalStudy: {
      theme: 'seaSerpent',
    },
    // Muu hoito lääkitysmoduulissa
    'd-vitamin': {
      theme: 'sunGlow',
    },
    // Lääkkeetön ajanjakso
    noMedication: {
      theme: 'darkCharcoal',
    },
  },
  graphSettings: {
    treatmentVisitsPerWeek: 'deepKoamaru',
    treatmentVisitsFourWeekAverage0: 'warningDefault',
    treatmentVisitsFourWeekAverage1: 'warningDefault',
    treatmentVisitsFourWeekAverage2: 'warningDefault',
    treatmentVisitsFourWeekAverage3: 'warningDefault',
    treatmentVisitsFourWeekAverage4: 'warningDefault',
    treatmentVisitsFourWeekAverage5: 'warningDefault',
    treatmentVisitsFourWeekAverage6: 'warningDefault',
    treatmentVisitsFourWeekAverage7: 'warningDefault',
    treatmentVisitsFourWeekAverage8: 'warningDefault',
    treatmentVisitsFourWeekAverage9: 'warningDefault',
    ledd: 'deepKoamaru',
    leddMax: 'warningDefault',
  },
  graphMenuSettings: {},
};

const defaultExpansionPanelTheme: TExpansionPanelTheme = {
  divider: '#045A8B',
  title: '#045A8B',
  addon: {
    title: '#1E274D',
    primary: '#045A8B',
  },
};

const defaultExpansionPanelThemeWithRedEvents: TExpansionPanelTheme = {
  divider: '#045A8B',
  title: '#045A8B',
  addon: {
    title: '#1E274D',
    primary: '#045A8B',
    priorityColors: {
      low: '#C20012',
      normal: '#C20012',
      high: '#C20012',
    },
  },
};

const defaultExpansionPanelThemeWithWarningEvents: TExpansionPanelTheme = {
  divider: '#045A8B',
  title: '#045A8B',
  addon: {
    title: '#1E274D',
    primary: '#045A8B',
    priorityColors: {
      low: '#045A8B',
      normal: '#DD8500',
      high: '#C20012',
    },
  },
};

const defaultSingleAddonTheme: TSingleAddonTheme = {
  divider: '#B9D1DF',
  addon: {
    title: '#045A8B',
    primary: '#045A8B',
  },
};

const defaultSingleAddonThemeWithRedEvents: TSingleAddonTheme = {
  divider: '#B9D1DF',
  addon: {
    title: '#045A8B',
    primary: '#045A8B',
    priorityColors: {
      low: '#C20012', // Error default
      normal: '#C20012',
      high: '#C20012',
    },
  },
};

const defaultSingleAddonThemeWithWarningEvents: TSingleAddonTheme = {
  divider: '#B9D1DF',
  addon: {
    title: '#045A8B',
    primary: '#045A8B',
    priorityColors: {
      low: '#7BA015', // Success
      normal: '#DD8500', // Warning default
      high: '#C20012', // Error default
    },
  },
};

const expansionPanelThemeMyms: TExpansionPanelTheme = {
  divider: '#2D7B83',
  title: '#2D7B83',
  addon: {
    title: '#2D7B83',
    primary: '#2D7B83',
  },
};

const singleAddonThemeMyms: TSingleAddonTheme = {
  divider: '#2D7B83',
  addon: {
    title: '#2D7B83',
    primary: '#2D7B83',
    priorityColors: {
      low: '#2D7B83',
      normal: '#C20012',
      high: '#C20012',
    },
  },
};

const getSingleAddonTheme = (theme: string | undefined): TSingleAddonTheme => {
  let singleAddonTheme: TSingleAddonTheme = defaultSingleAddonTheme;
  switch (theme) {
    case 'stellarqMy': {
      singleAddonTheme = singleAddonThemeMyms;
      break;
    }
    case 'defaultWithRedEvents': {
      singleAddonTheme = defaultSingleAddonThemeWithRedEvents;
      break;
    }
    case 'defaultWithWarningEvents': {
      singleAddonTheme = defaultSingleAddonThemeWithWarningEvents;
      break;
    }
    case 'mystic':
    case 'dollarBill':
    case 'interdimensionalBlue1':
    case 'aero':
    case 'vividOrange':
    case 'skyBlue':
    case 'darkRed':
    case 'seaSerpent':
    case 'interdimensionalBlue2':
    case 'hotMagenta':
    case 'mediumSlateBlue':
    case 'red':
    case 'caputMortuum':
    case 'bluePigment':
    case 'sunGlow':
    case 'darkCharcoal':
    case 'bitterLemon':
    case 'airForceBlue':
    case 'blueYonder':
    case 'egyptianBlue':
    case 'forestGreen':
    case 'delightfulPeach':
    case 'uglyPurple':
    case 'blueBonnet':
    case 'darkCyan2':
    case 'cafeAuLait':
    case 'warningDefault':
    case 'warningLight':
    case 'flirt': {
      singleAddonTheme = createSingleAddonTheme(AddonColors[theme]);
      break;
    }
  }
  return singleAddonTheme;
};

const createSingleAddonTheme = (theme: string): TSingleAddonTheme => {
  return {
    divider: theme,
    addon: {
      title: theme,
      primary: theme,
    },
  };
};

const createExpansionPanelTheme = (theme: string): TExpansionPanelTheme => {
  return {
    divider: theme,
    title: '#1E274D',
    addon: {
      title: '#1E274D',
      primary: theme,
    },
  };
};

const getExpansionPanelTheme = (theme: keyof typeof AddonColors | string | undefined): TExpansionPanelTheme => {
  let expansionPanelTheme: TExpansionPanelTheme = defaultExpansionPanelTheme;
  switch (theme) {
    case 'stellarqMy': {
      expansionPanelTheme = expansionPanelThemeMyms;
      break;
    }
    case 'defaultWithRedEvents': {
      expansionPanelTheme = defaultExpansionPanelThemeWithRedEvents;
      break;
    }
    case 'defaultWithWarningEvents': {
      expansionPanelTheme = defaultExpansionPanelThemeWithWarningEvents;
      break;
    }
    case 'mystic':
    case 'dollarBill':
    case 'interdimensionalBlue1':
    case 'aero':
    case 'vividOrange':
    case 'skyBlue':
    case 'darkRed':
    case 'seaSerpent':
    case 'interdimensionalBlue2':
    case 'hotMagenta':
    case 'mediumSlateBlue':
    case 'red':
    case 'caputMortuum':
    case 'bluePigment':
    case 'sunGlow':
    case 'darkCharcoal':
    case 'bitterLemon':
    case 'airForceBlue':
    case 'blueYonder':
    case 'egyptianBlue':
    case 'forestGreen':
    case 'delightfulPeach':
    case 'uglyPurple':
    case 'blueBonnet':
    case 'darkCyan2':
    case 'cafeAuLait':
    case 'warningDefault':
    case 'warningLight':
    case 'flirt': {
      expansionPanelTheme = createExpansionPanelTheme(AddonColors[theme]);
      break;
    }
  }
  return expansionPanelTheme;
};

const defaultGraphMenuTheme: TGraphMenuTheme = {
  color: { default: colors.appBlue.default, light: colors.appBlue.light, lightest: colors.appBlue.lightest },
  background: 'rgba(245, 245, 245, 1)',
  disabled: 'rgba(245, 245, 245, 0.7)',
};

const graphMenuThemeMyms: TGraphMenuTheme = {
  color: { default: colors.myms.primary, light: colors.myms.light, lightest: colors.myms.lightest },
  background: '#F2F7F8',
  disabled: 'rgba(4, 139, 130, 0.3)',
};

const getGraphMenuTheme = (theme: string | undefined): TGraphMenuTheme => {
  let graphMenuTheme: TGraphMenuTheme = defaultGraphMenuTheme;
  switch (theme) {
    case 'myms': {
      graphMenuTheme = graphMenuThemeMyms;
    }
  }
  return graphMenuTheme;
};

export { getSingleAddonTheme, getExpansionPanelTheme, getGraphMenuTheme };
