import * as React from 'react';
import { Icons, TAvailableIcons, customViewBox, TCustomViewBoxKey } from './AvailableIcons';
import colors from '../../../config/theme/colors';
import { ButtonBase } from '@mui/material';

const ColorMap: { [key: string]: string } = {
  appBlueMedium: colors.appBlue.medium,
  appBlueLight: colors.appBlue.light,
  warningDefault: colors.warning.default,
  errorDefault: colors.error.default,
  errorLight: colors.error.light,
  success: colors.success,
  grayMedium: colors.mediumGray,
  grayDark: colors.darkGray,
  color0: colors.warning.default,
  color1: '#960063', // Graph4
  color2: '#C20012', // Graph7
  color3: '#854EAF', // Graph11
};

/*
  This rounded style container and icon inside it are a bit wonky
  It should be the size of the supplied icon size, while the icon inside is a certain percentage smaller
  but depending where the icon is on the screen the alignment inside container might change
*/
const RoundedStyle: (color: string | number, size: number) => React.CSSProperties = (color, size) => ({
  width: size + 'rem',
  height: size + 'rem',
  backgroundColor: `${color in ColorMap ? ColorMap[color] : color}`,
  borderRadius: '10rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Icon = ({
  icon,
  color = colors.primary,
  secondaryColor = 'white',
  size = 2.5,
  round = false,
  button = false,
}: IIcon) => {
  const IconElement = button ? ButtonBase : 'div';
  const buttonProps = button ? { style: { verticalAlign: 'top' }, centerRipple: true } : {};
  return (
    <IconElement style={round ? RoundedStyle(color, size) : {}} {...buttonProps}>
      {React.createElement(Icons[icon], {
        style: {
          color: round ? secondaryColor : color in ColorMap ? ColorMap[color] : color,
          fontSize: round ? size * 0.84 + 'rem' : size + 'rem', // Rounded multiplier needs to be tested if changed, so that it scales well
          display: 'block',
        },
        viewBox: icon in customViewBox ? customViewBox[icon as TCustomViewBoxKey] : undefined,
      })}
    </IconElement>
  );
};

type TMappedColors = keyof typeof ColorMap;

interface IIcon {
  icon: TAvailableIcons;
  color?: TMappedColors | string;
  secondaryColor?: TMappedColors | string;
  round?: boolean;
  size?: number;
  /** The icon is rendered inside a button container, also adding a ripple effect when pushed */
  button?: boolean;
}

export default Icon;
