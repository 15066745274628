import * as React from 'react';

import { field, historyValue } from '../../../config/theme/componentTheme';
import { styled } from '@mui/system';

const StyledDiv = styled('div')({
  width: '100%',
});

const StyledInput = styled('input', {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})(({ width, fullWidth }: IInputProps) => ({
  boxSizing: 'border-box',
  width: fullWidth ? '100%' : width ? `${width}rem` : width,
  height: field.height,
  borderRadius: field.borderRadius,
  fontWeight: field.fontWeight,
  fontSize: field.fontSize,
  textAlign: 'left',
  fontFamily: 'Titillium Web',
  letterSpacing: field.letterSpacing,
  boxShadow: field.boxShadow,
  border: field.border,
  padding: field.padding,
}));

interface IInputProps {
  width?: number;
  fullWidth?: boolean;
}

const onChangeFormatted =
  (
    setCaretSelection: (pos: [number | null, number | null]) => void,
    onChange?: IInputBasics['onChange'],
    maxLength?: number,
    format?: RegExp,
    submit?: boolean,
  ) =>
  (e: React.ChangeEvent<HTMLInputElement>): void => {
    const target = { name: e?.currentTarget?.name, value: e?.currentTarget?.value };

    if (onChange && target.name && (target.value || target.value === '')) {
      if ((maxLength && (target.value ?? '').length > maxLength) || (format && !format.test(target.value))) {
        return;
      } else {
        submit && onChange({ [target.name]: target.value });
        setCaretSelection([e.target.selectionStart, e.target.selectionEnd]);
      }
    }
  };

const TextField = ({
  editing = false,
  name,
  value,
  placeholder,
  onChange,
  width,
  fullWidth,
  disabled,
  autoComplete = false,
  defaultText,
  maxLength,
  format,
  minCaretSelection,
  disableDelay = false,
}: IInputBasics & ITextField): JSX.Element => {
  const [prevValue, setPrevValue] = React.useState<typeof value>(value);
  const [shouldSaveValue, setShouldSaveValue] = React.useState<boolean>(false);

  if (defaultText && value === '') {
    value = defaultText;
  }

  // Used for same issue as with TextArea. Track caret position and prevent it from moving to the end of the value
  const [caretSelection, setCaretSelection] = React.useState<[number | null, number | null] | null>(null);

  const inputRef = React.useRef<HTMLInputElement>(null);

  // Handling of "legacy" minCaretSelection
  // This functionality should probably be replaced with a more elegant solution, such as a (fixed) prefix
  const caretSelectionCondition =
    !minCaretSelection ||
    (inputRef.current &&
      caretSelection &&
      (caretSelection[0] === null || caretSelection[0] >= minCaretSelection) &&
      (caretSelection[1] === null || caretSelection[1] >= minCaretSelection) &&
      typeof value === 'string' &&
      inputRef.current.value &&
      inputRef.current.value.substring(0, minCaretSelection) === value.substring(0, minCaretSelection));

  React.useEffect(() => {
    if (shouldSaveValue) {
      setPrevValue(inputRef.current?.value);
      onChange && onChange({ [name]: inputRef.current?.value });
      setShouldSaveValue(false);
    }
  }, [shouldSaveValue]);

  React.useLayoutEffect(() => {
    if (inputRef.current) {
      // If field is initialized externally
      if (value && (!inputRef.current.value || inputRef.current.value.length === 0)) {
        setPrevValue(value);
        inputRef.current.value = `${value}`;
        return;
      }

      // If field is emptied externally
      if (prevValue && !value && inputRef.current.value && inputRef.current.value.length > 0) {
        setPrevValue(value);
        inputRef.current.value = '';
        return;
      }
    }

    // Handling of "legacy" minCaretSelection
    if (minCaretSelection) {
      if (caretSelection && !caretSelectionCondition && inputRef.current) {
        inputRef.current.selectionStart =
          !minCaretSelection || caretSelection[0] === null || caretSelection[0] >= minCaretSelection
            ? caretSelection[0]
            : minCaretSelection;
        inputRef.current.selectionEnd =
          !minCaretSelection || caretSelection[1] === null || caretSelection[1] >= minCaretSelection
            ? caretSelection[1]
            : minCaretSelection;
        setShouldSaveValue(true);
        return;
      }

      if (caretSelection && caretSelection[0] === minCaretSelection && caretSelection[1] === minCaretSelection) {
        setShouldSaveValue(true);
        return;
      }
    }

    if (caretSelection && caretSelectionCondition && inputRef.current) {
      // This condition allows caret to stay in place when onChange is triggered after the timeout
      if (value !== inputRef.current.value) {
        inputRef.current.selectionStart = caretSelection[0];
        inputRef.current.selectionEnd = caretSelection[1];
      }

      const submit = setTimeout(() => {
        setShouldSaveValue(true);
      }, 500);

      return () => clearTimeout(submit);
    }
    return;
  }, [caretSelection, minCaretSelection, value]);

  return !editing ? (
    <StyledDiv style={historyValue}>{value || '-'}</StyledDiv>
  ) : (
    <StyledInput
      ref={inputRef}
      type="text"
      name={name}
      value={inputRef.current ? (caretSelectionCondition ? inputRef.current.value : value) : value}
      placeholder={placeholder}
      onChange={onChangeFormatted(setCaretSelection, onChange, maxLength, format, disableDelay)}
      onBlur={onChangeFormatted(setCaretSelection, onChange, maxLength, format, true)}
      onKeyDown={(e) => e.key === 'Enter' && onChange && onChange({ [name]: inputRef.current?.value })}
      width={width}
      fullWidth={fullWidth}
      disabled={disabled}
      autoComplete={autoComplete ? 'on' : 'off'}
    />
  );
};

export default TextField;
