import * as React from 'react';
import { scaleLinear } from 'd3-scale';

/**
 * Draggable component that can be used to make elements draggable
 * Found from stackoverflow:D
 * https://stackoverflow.com/questions/20926551/recommended-way-of-making-react-component-div-draggable
 */
class Draggable extends React.PureComponent<IOwnProps> {
  _relX = 0;
  _ref: React.RefObject<any> = React.createRef();

  private mounted = false;

  // Stuff to scale the dimensions relative to window to fit the svg container
  private svgRange = [this.props.margins.left, this.props.margins.right];
  private windowRange = [this.props.limits.left, this.props.limits.right];
  private scale = scaleLinear().domain(this.windowRange).range(this.svgRange);

  _onMouseDown = (event: any): void => {
    if (event.button !== 0) {
      return;
    }
    const { scrollLeft, clientLeft } = document.body;
    // Try to avoid calling `getBoundingClientRect` if you know the size
    // of the moving element from the beginning. It forces reflow and is
    // the laggiest part of the code right now. Luckily it's called only
    // once per click.
    const { left } = this._ref.current.getBoundingClientRect();
    this._relX = event.pageX - (left + scrollLeft - clientLeft);
    document.addEventListener('mousemove', this._onMouseMove);
    document.addEventListener('mouseup', this._onMouseUp);
    event.preventDefault();
  };

  _onMouseUp = (event: any): void => {
    document.removeEventListener('mousemove', this._onMouseMove);
    document.removeEventListener('mouseup', this._onMouseUp);
    event.preventDefault();
  };

  // This one's currently designed to serve needs of timeframeselector ruler
  // TODO design this so that it can be used both in svg elements with specified size and in normal html
  _onMouseMove = (event: any): void => {
    // Position relative to the window
    const newPosRelativeToWindow = event.pageX - this._relX;

    // Position relative to the parent svg element of the ruler
    let newPos = this.scale(newPosRelativeToWindow) ?? 0;

    // Do not allow the ruler to be moved out of the graph area
    if (newPos < this.props.margins.left) newPos = this.props.margins.left;
    if (newPos > this.props.margins.right) newPos = this.props.margins.right;

    // make the move
    this.props.onMove(newPos);
    event.preventDefault();
  };

  _update = () => {
    const { x, y } = this.props;
    const refElement = this._ref;
    if (refElement) {
      refElement.current.style.transform = `translate(${x}px, ${y}px)`;
    }
  };

  componentDidMount(): void {
    this.mounted = true;
    this._ref.current.addEventListener('mousedown', this._onMouseDown);
    this._update();
    if (this.mounted) {
      // Should probably be implemented in some other way
      // Find boundaries of the parent element of the ruler
      const elmnt = document.getElementById(this.props.parentId)?.getBoundingClientRect();
      // Assign left and right values of the parent of the ruler into the windowRange variable
      this.windowRange = [elmnt?.left ?? 0, elmnt?.right ?? 0];

      this.scale = scaleLinear().domain(this.windowRange).range(this.svgRange);
    }
  }

  componentDidUpdate(): void {
    this._update();
  }

  componentWillUnmount(): void {
    this._ref.current.removeEventListener('mousedown', this._onMouseDown);
    this.mounted = false;
  }

  render(): any {
    return (
      <div id="draggable" style={{ height: '100%', width: 5, userSelect: 'none' }} ref={this._ref}>
        {this.props.children}
      </div>
    );
  }
}

interface IOwnProps {
  x: number; // X value of the component
  y: number; // Y value of the component. Doesnt support moving of the element in y-direction
  onMove: (x: number) => void; // Function for changing the x-position of the component
  limits: { right: number; left: number }; // Limits of the component relative to window
  margins: { left: number; right: number }; // Limits of the component relative to parent element
  parentId: string; // id of the parent element of the component
  children: React.ReactNode;
}

export default Draggable;
