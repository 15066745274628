import { Paper, PaperProps } from '@mui/material';
import { styled } from '@mui/system';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import colors from '../../config/theme/colors';
import { getSectionTitle } from '../../utility/randomUtil';

import { DocumentsLengthIndicator } from './TileContentMaker/components/common/commonComponents';
import { Container, Item } from 'Components/Grid';

const StyledPaper = styled(({ id, ...other }: { id: string } & PaperProps) => <Paper id={id} {...other} />)(
  ({ theme }) => ({
    width: theme.customComponents.tile.width,
    height: theme.customComponents.tile.height,
    margin: theme.customComponents.tile.margin,
    padding: theme.customComponents.tile.padding,
    '&:hover': {
      backgroundColor: '#f8f8f8',
    },
    overflow: 'hidden',
  }),
);

const StyledHeader = styled('div')(({ theme }) => ({
  ...theme.customComponents.tile.header,
  color: theme.customComponents.tile.fonts.header.color,
  fontSize: theme.customComponents.tile.fonts.header.size,
}));

const StyledBody = styled('div')(({ theme }) => ({
  ...theme.customComponents.tile.body,
}));

const StyledContainer = styled(Container)({
  height: '100%',
});

const StyledItem = styled(Item)({});

const EditingOverlay = styled('div')(({ theme }) => ({
  width: 'auto',
  height: theme.customComponents.tile.overlay.height,
  margin: theme.customComponents.tile.overlay.margin,
  backgroundColor: theme.palette.primary.main,
  display: 'block',
  zIndex: 10,
  opacity: 0.5,
}));

const EditingTextBox = styled('div')({
  fontSize: '1.6rem',
  color: colors.white,
  border: `1px solid ${colors.white}`,
  padding: '0.25rem 1.5rem',
  backgroundColor: 'rgba(4, 90, 139, 0.8)',
  textTransform: 'uppercase',
  fontWeight: 600,
  '&:hover': {
    backgroundColor: 'rgba(4, 90, 139, 1)',
  },
  zIndex: 15,
});

const noDocsElement = (
  <StyledContainer justifyContent="center" alignItems="center" style={{ height: '100%' }}>
    <StyledItem style={{ fontSize: 36, color: colors.lightGray, marginTop: '-5rem' }}>
      <FormattedMessage id="general.noData" />
    </StyledItem>
  </StyledContainer>
);

const editingOverlayElement = (
  <>
    <EditingOverlay></EditingOverlay>
    <div style={{ marginTop: '-16rem' }}>
      <StyledContainer justifyContent="center" alignItems="center" style={{ height: '100%' }}>
        <StyledItem style={{ zIndex: 15 }}>
          <EditingTextBox>
            <FormattedMessage id="general.editing" />
          </EditingTextBox>
        </StyledItem>
      </StyledContainer>
    </div>
  </>
);

const tileHeader = (title: string, icon?: JSX.Element, documentsLength?: number | null): JSX.Element => (
  <StyledHeader>
    <StyledContainer alignItems="center" style={{ height: '100%' }}>
      {icon && <StyledItem style={{ marginRight: '1rem' }}>{icon}</StyledItem>}
      <StyledItem xs={true}>
        <FormattedMessage id={title} />
      </StyledItem>
      {documentsLength && documentsLength > 0 ? (
        <StyledItem>
          <DocumentsLengthIndicator length={documentsLength} />
        </StyledItem>
      ) : null}
    </StyledContainer>
  </StyledHeader>
);

const Tile = ({
  section,
  content,
  editing = false,
  noDocs = false,
  icon,
  platform,
  documentsLength,
}: IOwnProps): JSX.Element => (
  <React.Fragment>
    <StyledPaper id={section} elevation={1} square={true}>
      <StyledContainer direction="column" style={{ overflow: 'inherit', height: '100%' }}>
        <StyledItem style={{ width: '100%' }}>
          {tileHeader(`${section}.${getSectionTitle(section, platform)}`, icon, documentsLength)}
        </StyledItem>
        <StyledItem xs={true} style={{ overflow: 'hidden' }}>
          <StyledBody>{noDocs ? noDocsElement : content}</StyledBody>
        </StyledItem>
      </StyledContainer>
    </StyledPaper>
    {editing && editingOverlayElement}
  </React.Fragment>
);

interface IOwnProps {
  section: string;
  content: JSX.Element;
  editing?: boolean;
  noDocs?: boolean;
  icon?: JSX.Element;
  platform?: Platform;
  documentsLength?: number | null;
}

export default Tile;
