import { path, filter } from 'ramda';

import { LoadableDocConfig, loadableDocumentTypes } from '../config/generalSettings';
import neuroLanguage from './browserLanguage';
import { sortPartialDate, sortTime } from 'neuro-utils';
import { fetchWithOptions } from './fetch';
import { parseJWTFromCookie } from './jwtAuthTools';
import { INeuroDocument } from 'neuro-data-structures';
import { makeLog } from './logger';

/**
 * Simple function to find field value from object
 * @param {object} document - Target object
 * @param {string} name - Field name
 * @return {value|undefined} Return field value or undefined
 */
export const fetchValue = (document: { [key: string]: any }, name: string): number | string | undefined =>
  path([name], document) ? path([name], document) : undefined;

/**
 * Get enabled documents based on platform, as string array
 * @param {ISession} session - Session state
 * @param {document[]} documents - Documents
 * @return {LoadableDocConfig[]} Document type array
 */
export const getEnabledDocuments = <T extends Pick<ISessionStore, 'platforms'>>(session: T): LoadableDocConfig[] => {
  const platform = session?.platforms?.selected || '';

  let commonRoutes = loadableDocumentTypes.common;

  // Enabled tiles/documents based on diagnosis
  const diagnosisRoutes = loadableDocumentTypes[platform] || [];

  // Add diagnosis route to commonRoutes if no platform is selected
  if (platform === '') commonRoutes = commonRoutes.concat([{ name: 'diagnosis' }]);

  return commonRoutes.concat(diagnosisRoutes);
};

/**
 * Get navigable routes based on platform, as string array
 * @param {ISession} session - Session state
 * @param {document[]} documents - Documents
 * @return {LoadableDocConfig[]} Document type array
 */
export const getNavigableRoutes = (session: ISessionStore): LoadableDocConfig[] => {
  const platform = session?.platforms?.selected || '';

  const commonRoutes = loadableDocumentTypes.common;

  const diagnosisRoutes = loadableDocumentTypes[platform] || [];
  const navigableRoutes = diagnosisRoutes.filter((d) => !d.nonNavigable);

  return commonRoutes.concat(navigableRoutes);
};

/**
 * Get platform specific section title
 * @param {string} section Section name
 * @param {platform} platform Selected platform
 * @return {string} Section title, 'title' if not specified
 */
export const getSectionTitle = (section: string, platform?: Platform): string => {
  if (
    (section === 'comorbidity' && platform === 'mgravis') ||
    (section === 'clpAndCnpTests' && platform === 'mgravis')
  ) {
    return 'mgravis.title';
  }
  const currentSection = platform
    ? getEnabledDocuments({ platforms: { selected: platform, available: [] } }).find(
        (d: LoadableDocConfig) => path(['name'], d) === section,
      )
    : { title: undefined };
  return currentSection?.title ?? 'title';
};

export const sortStrengths = (first: { [key: string]: string }, second: { [key: string]: string }): number => {
  // Get the strengths as numbers and compare them. Return 1, 0 or -1 depending on whether the first or the second is bigger
  const s1 = parseInt(first[Object.keys(first)[0]].split(' ')[0]);
  const s2 = parseInt(second[Object.keys(second)[0]].split(' ')[0]);
  if (s1 > s2) {
    return 1;
  } else if (s1 < s2) {
    return -1;
  } else {
    return 0;
  }
};

/**
 * Filter unfinished documents and return document array with only completed commits
 * @param  {INeuroDocument[]} d - Document array to be filtered
 * @returns {INeuroDocument[]} INeuroDocument
 */
export const filterUnfinishedDocs = (d: INeuroDocument[]): INeuroDocument[] =>
  filter((d) => filter((c) => c.commitDate !== null, d.commits).length !== 0, d);

/**
 * Function for getting icd10 codes
 * @param ICD10SearchString icd10 search string
 * @param locale locale for which the codes should be
 * @returns array of icd10 codes
 */
export const getICD10Codes = (ICD10SearchString: string, locale: string): Promise<ICD10Item[]> => {
  return fetchWithOptions(
    `/api/icd-code-search/v1/search?search=${ICD10SearchString}&locale=${locale}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'GET' },
  )
    .then((res: Response) => {
      if (res.status === 200) return res.json();
      throw { status: res.status, fullResponse: res };
    })
    .catch((err) => {
      makeLog('Error', { name: 'ICD-codes fetch', message: 'Failed to GET icd-codes' }, err);
      return [] as ICD10Item[];
    });
};

/**
 * Function for getting descrition of icd10 code in language of browser
 * @param icd10 icd10 code
 * @returns description of icd10 code in language of browser
 */
export const getDescription = (icd10: string): Promise<string> => {
  const code = encodeURIComponent(icd10);
  return fetchWithOptions(
    `/api/icd-code-search/v1/description?codeVer=icd10&locale=${neuroLanguage}&code=${code}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'GET' },
  )
    .then((res: Response) => {
      if (res.status === 200) return res.json();
      else return '';
    })
    .catch((err) => {
      makeLog('Error', { name: 'ICD-codes description fetch', message: 'Failed to GET icd-code description' }, err);
    });
};

type TSortableDocument = { [field: string]: any } & Partial<IControlProps>;

/**
 * Document sorting function, by default sort from newest to oldest
 * @param {Array<{ type: 'date'|'time'; sortField: string }>} sortingSettings - Array of types and field names to sort documents by
 * @returns {number} Number
 */
export const sortDocuments =
  (sortingSettings: Array<{ type: 'date' | 'time'; sortField: string }>) =>
  (doc1: TSortableDocument, doc2: TSortableDocument): number => {
    let result = 0;

    sortingSettings.forEach((sort) => {
      if (result !== 0) return;
      if (sort.type === 'date')
        result = sortPartialDate(doc2[sort.sortField] as PartialDate, doc1[sort.sortField] as PartialDate);
      if (sort.type === 'time') result = sortTime(doc2[sort.sortField] as Time, doc1[sort.sortField] as Time);
    });

    if (result === 0) result = (doc2._cdate || 0) - (doc1._cdate || 0); // Sort by commitdate
    return result;
  };
