/**
 * Delete dialog
 */
import { Dialog, DialogActions, DialogContent, DialogTitle, Paper, PaperProps } from '@mui/material';
import { mergeRight } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { styled } from '@mui/system';

import { dialogActions, dialogCancel, dialogContent, dialogTitle } from '../../config/theme/componentTheme';
import { actions } from '../../store/documents/actions';
import ActionButton from '../ActionButton';
import InputHandler from '../InputHandler';
import { Container, Item } from 'Components/Grid';

const StyledRowItem = styled(Item)({});

const InputRowContainer = styled(Container)({
  margin: '0 0 3rem 0',
});

const DialogPaper = (props: PaperProps): JSX.Element => <Paper square={true} {...props} />;

const optionFormatter = (n: string | number): JSX.Element => <FormattedMessage id={'general.opts.' + n} />;

type Props = IOwnProps & IDispatchFromProps;

class DeleteDialog extends React.Component<Props, { deleteDetails: IDeleteDetails }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      deleteDetails: {
        date: undefined,
        reason: '',
        reasonOther: '',
      },
    };
  }

  public onChangeDetail = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    // deepcode ignore ReactNextState: Needs rework to fix
    this.setState({
      deleteDetails: mergeRight(this.state.deleteDetails, {
        [name]: value as any,
      }),
    });
  };

  public deleteHandler = (): void => {
    const { _id, _type } = this.props.document;
    const { date, reason, reasonOther } = this.state.deleteDetails;
    const { subDocs } = this.props;
    if (date && reason) {
      this.props.deleteDocument({ name: _type, id: _id }, { date, reason, reasonOther }, subDocs);
      this.props.setOpen();
    }
  };

  public render(): JSX.Element {
    return (
      <Dialog open={this.props.open} maxWidth={'md'} fullWidth={true} PaperComponent={DialogPaper}>
        <DialogTitle style={dialogTitle}>
          <FormattedMessage id="general.deletionTitle" />
        </DialogTitle>
        <DialogContent style={dialogContent}>
          {this.props.customDeleteContent || (
            <>
              <InputRowContainer>
                <StyledRowItem xs={4}>
                  <FormattedMessage id="general.date" />
                </StyledRowItem>
                <StyledRowItem xs={8}>
                  <InputHandler
                    type="PartialDate"
                    editing={true}
                    name="date"
                    formData={{
                      onChange: this.onChangeDetail,
                      document: { date: this.state.deleteDetails.date },
                    }}
                    dateDefault="now"
                    isNotCancellable={true}
                  />
                </StyledRowItem>
              </InputRowContainer>
              <InputRowContainer>
                <StyledRowItem xs={4}>
                  <FormattedMessage id="general.reason" />
                </StyledRowItem>
                <StyledRowItem xs={8}>
                  <InputHandler
                    type="Radio"
                    editing={true}
                    name="reason"
                    formData={{
                      onChange: this.onChangeDetail,
                      document: { reason: this.state.deleteDetails.reason },
                    }}
                    options={['duplicate', 'mistake', 'other']}
                    optionFormatter={optionFormatter}
                  />
                </StyledRowItem>
              </InputRowContainer>
              <InputRowContainer>
                <StyledRowItem xs={4}>
                  <FormattedMessage id="general.reasonOther" />
                </StyledRowItem>
                <StyledRowItem xs={8}>
                  <InputHandler
                    type="TextField"
                    editing={true}
                    name="reasonOther"
                    formData={{
                      onChange: this.onChangeDetail,
                      document: { reasonOther: this.state.deleteDetails.reasonOther },
                    }}
                  />
                </StyledRowItem>
              </InputRowContainer>
            </>
          )}
        </DialogContent>
        <DialogActions style={dialogActions}>
          <div style={dialogCancel} onClick={this.props.setOpen}>
            <FormattedMessage id="general.cancel" />
          </div>
          <ActionButton
            text="general.delete"
            onClick={() => {
              this.props.customDeleteHandler
                ? this.props.customDeleteHandler(this.state.deleteDetails)
                : this.deleteHandler();
            }}
            width={15}
            height={4}
            fontSize={16}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

export interface IDeleteDetails {
  date?: PartialDate;
  reason: string;
  reasonOther: string;
}

interface IDispatchFromProps {
  deleteDocument: (
    editingDocument: { name: string; id: string },
    reason: { [key: string]: string | PartialDate },
    subDocs?: Array<{ name: string; id: string } | undefined>,
  ) => void;
}

const mapDispatchToProps = (dispatch: any): any => ({
  deleteDocument: (
    editingDocument: { name: string; id: string },
    reason: { [key: string]: string | PartialDate },
    subDocs?: Array<{ name: string; id: string } | undefined>,
  ): void => dispatch(actions.deleteDocument(editingDocument, reason, subDocs)),
});

interface IOwnProps {
  document: IControlProps;
  open: boolean;
  setOpen: () => void;
  subDocs?: Array<{ name: string; id: string } | undefined>;
  customDeleteContent?: JSX.Element;
  customDeleteHandler?: (deleteDetails?: IDeleteDetails) => void;
}

export default connect<IOwnProps, IDispatchFromProps>(null, mapDispatchToProps)(DeleteDialog);
