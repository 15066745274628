import * as React from 'react';

interface IOwnProps {
  strokeColor: string;
  strokeWidth: number;
  fillColor: string;
  n: number | string;
  active?: boolean;
  priority: 'low' | 'normal' | 'high' | undefined;
  textColor: string;
  addon?: boolean;
}

const isDoubleDigit = (n: string | number): boolean =>
  typeof n === 'number' && (n.toString().length === 2 ? true : false);

export const IconMultipleEvents = ({
  strokeColor,
  strokeWidth,
  fillColor,
  textColor,
  n,
  active = false,
  priority,
  addon = false,
}: IOwnProps): JSX.Element => {
  const [hover, setHover] = React.useState<boolean>(false);

  const mouseEnter = (): void => {
    if (active) {
      setHover(true);
    }
  };

  const mouseLeave = (): void => {
    setHover(false);
  };

  const isDoubleD = isDoubleDigit(n);

  const width = isDoubleD ? 18 : 14;
  const height = 18;

  const scaleOnHover = 1.05;

  return (
    <g
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      overflow="visible"
      transform={
        addon
          ? undefined
          : `translate(${hover ? (-width * scaleOnHover) / 2 : -width / 2},${
              hover ? (-height * scaleOnHover) / 2 : -height / 2
            }) scale(${hover ? scaleOnHover : 1})`
      }
    >
      <rect
        x={0}
        y={0}
        width={width}
        height={height}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        fill={fillColor}
        strokeDasharray={
          priority === 'normal' && !isDoubleD
            ? '2 2 4 2 2 0 2 2 4 2 4 2 2 0 2 2 4 2 2 0 2 2 4 2 4 2 2'
            : priority === 'normal' && isDoubleD
              ? '2 2 4 2 4 2 2 0 2 2 4 2 4 2 2 0 2 2 4 2 4 2 2 0 2 2 4 2 4 2 2'
              : undefined
        }
      />
      <text
        x={width / 2}
        y={height / 2 + 1}
        fill={textColor}
        fontSize={12}
        fontWeight="bold"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ userSelect: 'none' }}
      >
        {isDoubleD ? '9+' : n}
      </text>
    </g>
  );
};
