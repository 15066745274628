import { Select, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/system';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import { FormattedMessage } from 'react-intl';

import * as React from 'react';

import { field, historyValue } from '../../../config/theme/componentTheme';

const StyledDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ width }: { width?: number }) => ({
  maxWidth: width ? width + 'rem' : field.width,
}));

interface ITextElementsProps {
  value?: string | number;
  optionFormatter?: (name: string | number) => JSX.Element | string;
}

const TextElements = ({ value, optionFormatter }: ITextElementsProps): JSX.Element => (
  <StyledDiv>{optionFormatter && typeof value === 'string' ? optionFormatter(value) : value}</StyledDiv>
);

const SelectElements = ({
  name,
  value,
  options,
  onChange,
  optionFormatter,
  fullWidth,
  placeholder,
  formatTitle,
}: Partial<IInputBasics & ISelectByCategory>): JSX.Element => {
  const selectOnChange = (event: SelectChangeEvent<any>): void | undefined => {
    event.target.name && onChange && onChange({ [event.target.name as string]: event.target.value });
  };

  return (
    <StyledDiv>
      <Select
        name={name}
        value={value}
        onChange={selectOnChange}
        fullWidth={fullWidth}
        displayEmpty={true}
        style={value === '' ? { fontStyle: 'italic' } : {}}
      >
        {placeholder && (
          <MenuItem key={name + 'placeholder'} value={''} disabled={true} style={{ fontStyle: 'italic' }}>
            {placeholder}
          </MenuItem>
        )}
        {options &&
          options.map((item) => [
            item.title ? (
              <ListSubheader key={item.title} disableSticky={true}>
                {formatTitle ? <FormattedMessage id={`${item.title}`} /> : item.title}
              </ListSubheader>
            ) : (
              ''
            ),
            item.values
              ? item.values.map((o: string) => (
                  <MenuItem key={o} value={o}>
                    {optionFormatter ? optionFormatter(o) : o}
                  </MenuItem>
                ))
              : [],
          ])}
      </Select>
    </StyledDiv>
  );
};

const SelectsByCategory = ({
  editing = false,
  name,
  onChange,
  value,
  options,
  optionFormatter,
  fullWidth,
  placeholder,
  formatTitle,
}: IInputBasics & ISelectByCategory): JSX.Element =>
  !editing ? (
    <div style={historyValue}>
      <TextElements value={value} optionFormatter={optionFormatter} />
    </div>
  ) : (
    <SelectElements
      name={name}
      value={value}
      options={options}
      onChange={onChange}
      optionFormatter={optionFormatter}
      fullWidth={fullWidth}
      placeholder={placeholder}
      formatTitle={formatTitle}
    />
  );

export default SelectsByCategory;
