import * as React from 'react';
import { FormattedMessage } from 'react-intl';

// Types of regimen and medications which use them
export const regimenTypesByMed = {
  default: ['STALEVO', 'DUODOPA'],
  static: ['SPINRAZA'],
} as { [key: string]: string[] };

// Match substances and dosage form to regimen type
export const regimenTypesBySubstanceAndDosageform = [
  { substance: /apomorfiini|foslevodopa/, dosageForm: /infuusioneste/, regimen: 'custom' },
  { substance: /levodopa/, dosageForm: /geeli suoleen/, regimen: 'custom' },
] as Array<{ substance: RegExp; dosageForm: RegExp; regimen: string }>;

// Which regimen types are shown in regimen selection by platform
export const regimenTypesByPlatform = {
  sma: ['default', 'onDemand', 'custom', 'single-dose'],
  dmd: ['default', 'onDemand', 'custom', 'single-dose'],
  parkinson: ['default', 'onDemand', 'custom', 'single-dose'],
  ms: ['default', 'onDemand', 'custom', 'single-dose'],
  other: ['default', 'onDemand', 'custom', 'single-dose'],
} as { [key: string]: string[] };

export type TMsATCCode =
  | 'L03AB07' // avonex, rebif, betainterferon
  | 'L03AB08' // betaferon, extavia, betainterferon
  | 'L03AB' // undefined betainterferon
  | 'L03AX13' // copaxone
  | 'L04AA27' // gilenya
  | 'L04AE01' // gilenya 2
  | 'L04AA23' // tysabri
  | 'L04AG03' // tysabri 2
  | 'L04AA34' // lemtrada
  | 'L04AG06' // lemtrada 2
  | 'L04AX07' // tecfidera
  | 'L04AA31' // aubagio
  | 'L04AK02' // aubagio 2
  | 'L03AB13' // plegridy, betainterferon
  | 'L04AC01' // zinbryta
  | 'L04AA40' // mavenclad
  | 'L04AA42' // mayzent
  | 'L04AE03' // mayzent 2
  | 'L04AA36' // ocrevus
  | 'L04AG08' // ocrevus 2
  | 'L01XC02' // mabhtera
  | 'L01FA01' // mabthera 2
  | 'L01DB07' // mitoksantroni
  | 'L04AX01' // atsatiopriini
  | 'L01XC10' // ofatumumab
  | 'L04AA52' // ofatumumab 2
  | 'L01FA02' // ofatumumab 3
  | 'L04AG12' // ofatumumab 4
  | 'L04AA50' // ponesimodi
  | 'L04AE04' // ponvory
  | 'L04AX09' // vumerity
  | 'L04AA38' // zeposia
  | 'L04AE02'; // zeposia 2

// ATC codes for disease modifying medications by platform
export const atcCodesByPlatform: { [key: string]: Array<string> } = {
  sma: [
    'C', // SYDÄN- JA VERISUONISAIRAUKSIEN LÄÄKKEET
    'H02', // SYSTEEMISESTI KÄYTETTÄVÄT KORTIKOSTEROIDIT
    'R03', // AHTAUTTAVIEN HENGITYSTIESAIRAUKSIEN LÄÄKKEET
    'M05B', //LUUN RAKENTEESEEN JA MINERALISAATIOON VAIKUTTAVAT LÄÄKKEET
    'M09AX07', // nusinerseeni
    'M09AX09', // onasemnogeeniabeparvoveekki
    'M09AX10', // risdiplaami
  ],
  dmd: [
    'C', // SYDÄN- JA VERISUONISAIRAUKSIEN LÄÄKKEET
    'H02', // SYSTEEMISESTI KÄYTETTÄVÄT KORTIKOSTEROIDIT
    'R03', // AHTAUTTAVIEN HENGITYSTIESAIRAUKSIEN LÄÄKKEET
    'M05B', // LUUN RAKENTEESEEN JA MINERALISAATIOON VAIKUTTAVAT LÄÄKKEET
    'M09AX03', // atalureeni
  ],
  parkinson: [
    'N04', // anti-parkinson drugs
    'N05', // psycholeptics drugs
    'N06', // psychoanaleptics drugs
  ],
  ms: [
    'L03AB07', // avonex, rebif, betainterferon
    'L03AB08', // betaferon, extavia, betainterferon
    'L03AB', // undefined betainterferon
    'L03AX13', // copaxone
    'L04AA27', // gilenya
    'L04AE01', // gilenya 2
    'L04AA23', // tysabri
    'L04AG03', // tysabri 2
    'L04AA34', // lemtrada
    'L04AG06', // lemtrada 2
    'L04AX07', // tecfidera
    'L04AA31', // aubagio
    'L04AK02', // aubagio 2
    'L03AB13', // plegridy, betainterferon
    'L04AC01', // zinbryta
    'L04AA40', // mavenclad
    'L04AA42', // mayzent
    'L04AE03', // mayzent 2
    'L04AA36', // ocrevus
    'L04AG08', // ocrevus 2
    'L01XC02', // mabhtera
    'L01FA01', // mabthera 2
    'L01DB07', // mitoksantroni
    'L04AX01', // atsatiopriini
    'L01XC10', // ofatumumab
    'L04AA52', // ofatumumab 2
    'L01FA02', // ofatumumab 3
    'L04AG12', // ofatumumab 4
    'L04AA50', // ponesimodi
    'L04AE04', // ponvory
    'L04AX09', // vumerity
    'L04AA38', // zeposia
    'L04AE02', // zeposia 2
  ],
  huntington: [
    'N05A', // PSYKOOSILÄÄKKEET
    'N05B', // NEUROOSILÄÄKKEET JA RAUHOITTAVAT AINEET
    'N05C', // UNILÄÄKKEET
    'N06A', // MASENNUSLÄÄKKEET
    'N03AE01', // klonatsepaami
    'N07XX06', // tetrabenatsiini
  ],
  epilepsy: [
    'N03', // Epilepsialääkkeet
    'H02AB06', // prednisoloni - This and the following drugs added 2021-11-10
    'H02AB04', // metyyliprednisoloni
    'H01AA02', // tetrakosaktidi (ACTH)
    'L01EG02', // everolimuusi
    'L04AH02', // everolimuusi 2
    'N05CD08', // midatsolaami
    'N05BA01', // diatsepaami
    'N05BA09', // klobatsaami
  ],
  sleepApnea: [
    'R03', // AHTAUTTAVIEN HENGITYSTIESAIRAUKSIEN LÄÄKKEET
    'N05', // PSYKOOSI- JA NEUROOSILÄÄKKEET SEKÄ UNILÄÄKKEET
    'N06', // MASENNUSLÄÄKKEET JA KESKUSHERMOSTOA STIMULOIVAT LÄÄKEAINEET
    'N07', // MUUT HERMOSTOON VAIKUTTAVAT LÄÄKEAINEET
    'S01EC', // Hiilihappoanhydraasin estäjät
    'N06BA14', // solriamfetoli – vain jos potilaalla on G47.3 (yksin tai J96.9/J96.1 kera)
    'C01CA', // Tää sisältää duplikaatteja R03-luokan kanssa, mutta koska C01CA on aakkosissa ennen (?) päätyy lääkitysdokumentteihin tää luokka atc-koodiks
  ],
  ninmt: [
    'M01A', // TULEHDUSKIPU- JA REUMALÄÄKKEET
    'M02', // LIHAS- JA NIVELSÄRKYJEN PAIKALLISHOITOON TARKOITETUT VALMISTEET
    'N02', // ANALGEETIT
    'N03', // EPILEPSIALÄÄKKEET
    'N05', // PSYKOOSI- JA NEUROOSILÄÄKKEET SEKÄ UNILÄÄKKEET
    'N06A', // MASENNUSLÄÄKKEET
    'N01BX04', // kapsaisiini
  ],
  mgravis: [
    'H02AB04', // metyyliprednisoloni
    'H02AB06', // prednisoloni
    'J06BA02', // ihmisen normaali immunoglobuliini intravaskulaariseen käyttöön
    'L01AA01', // syklofosfamidi
    'L04AD01', // siklosporiini
    'L01FA01', // rituksimabi
    'L04AA06', // mykofenolihappo
    'L04AA25', // ekulitsumabi
    'L04AJ01', // ekulitsumabi 2
    'L04AA58', // efgartigimodi alfa
    'L04AD02', // takrolimuusi
    'L04AX01', // atsatiopriini
    'L04AX03', // metotreksaatti
    'N07AA02', // pyridostigmiini
    'N07AA03', // distigmiinibromidi
    'L04AA43', // ravulitsumabi
    'L04AJ02', // ravulitsumabi 2
  ],
};

// Define the elements which are shown with static regimen, instead of regimen controls
export const staticRegimenElements = {
  SPINRAZA: (
    <div>
      <div style={{ fontWeight: 600 }}>
        <FormattedMessage id="medication.staticStart" />
      </div>
      <div>
        <FormattedMessage id="medication.spinraza.start" />
      </div>
      <div style={{ fontWeight: 600, marginTop: '2rem' }}>
        <FormattedMessage id="medication.staticUpkeep" />
      </div>
      <div>
        <FormattedMessage id="medication.spinraza.upkeep" />
      </div>
    </div>
  ),
} as { [key: string]: JSX.Element };

// Match substances to substance categories
export const medicationSubstancesByCategory = {
  levodopa: /levodopa/,
  maobInhibitors: /rasagiliini|selegiliini|safinamidi/,
  dopamineAgonists: /rotigotiini|apomorfiini|bromokriptiini|kabergoliini|pramipeksoli|ropiniroli/,
} as { [key: string]: RegExp };

export const pausationReasons: TPausationReason[] = [
  'condition',
  'otherMedication',
  'patientBasedOtherReason',
  'clinicallyIneffective',
  'adverseEffect',
  'treatment',
  'demobilize',
  'temporaryChange',
  'other',
];
